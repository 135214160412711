import React, { useState } from 'react';
import Web3 from 'web3';
import web3config from './web3config';
import presaleAbi from '../abi/presaleABI.json';

const useAirdropStatus = () => {
  const airdropStatus = async (id) => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });

        const web3 = new Web3(window.ethereum);
        const preSaleContract = web3config.preSaleContract;
        const contract = new web3.eth.Contract(presaleAbi, preSaleContract);

        const accounts = await web3.eth.getAccounts();
        const userAddress = accounts[0];

        const infooo = await contract.methods.isAirdropActive(id).call();
        return infooo;
      } catch (error) {
        console.log('Error:', error);
        alert('Error getting details. Please try again.');
      }
    } else {
      alert('Please install MetaMask to proceed.');
    }
  };

  return { airdropStatus };
};

export { useAirdropStatus };
