import React, { useState } from "react";
import Web3 from "web3";
import web3config from "./web3config";
import presaleAbi from "../abi/presaleABI.json";
import { toast } from "react-hot-toast";

const useBuyStatus = () => {
  const buyStatus = async (id) => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });

        const web3 = new Web3(window.ethereum);
        const preSaleContract = web3config.preSaleContract;
        const contract = new web3.eth.Contract(presaleAbi, preSaleContract);

        const accounts = await web3.eth.getAccounts();
        const userAddress = accounts[0];

        const infooo = await contract.methods.isBuyActive(1).call();
        // console.log('infooo',infooo)
        return infooo;
        // console.log("Phase Details", infooo/10**18);
      } catch (err) {
        // if (err.message.toString().split("Insufficient fund")[1]) {
        //   toast.error("Transaction reverted : " + err.message);
        // } else {
        //   if (err.toString().split("execution reverted:")[1]) {
        //     toast.error(
        //       "Transaction reverted : " +
        //         err
        //           .toString()
        //           .split("execution reverted:")[1]
        //           .toString()
        //           .split("{")[0]
        //     );
        //   } else {
        //     toast.error(err.message);
        //   }
        // }
      }
    } else {
      alert("Please install MetaMask to proceed.");
    }
  };

  return { buyStatus };
};

export { useBuyStatus };
