import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../generalCSS/home.css";
import { MdOutlineSwapVert } from "react-icons/md";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Accordion,
} from "react-bootstrap";
import Web3 from "web3";
import web3config from "../components/web3config";
import Header from "./header";
import Footer from "./footer";
import Cookies from "js-cookie";
import config from "../config/config";
import { Zoom, Fade, Slide, Roll, LightSpeed, Bounce } from "react-reveal";
import { useBuyToken } from "../components/buyToken";
import { useBuyStatus } from "../components/isBuyActive";
import { useTotalSold } from "../components/totalSold";
import { useClaimToken } from "../components/claimToken";
import { useSetActivePhase } from "../components/admin/setActivePhase";
import { useStartAirDrop } from "../components/admin/startAirdrop";
import { useStopAirDrop } from "../components/admin/stopAirdrop";
import { useStartBuy } from "../components/admin/startBuy";
import { useStopBuy } from "../components/admin/stopBuy";
import { useTokenPrice } from "../components/tokenPrice";
import { Dialog, Classes } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import { useBuyInfo } from "../components/userBuyInfo";
import toast, { Toaster } from "react-hot-toast";
import Rangeslider from "./rangeSlider";
import detectEthereumProvider from "@metamask/detect-provider";
import usdtAbi from "../abi/usdtABI.json";
const Presale = () => {
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState(
    !Cookies.get("likeBitUser") ? [] : JSON.parse(Cookies.get("likeBitUser"))
  );
  const [inputValue, setInputValue] = useState("");
  const [walletaddres, setWalletAddress] = useState(
    !localStorage.getItem("walletaddress")
      ? ""
      : localStorage.getItem("walletaddress")
  );

  const [realAmountData, setrealAmountData] = useState(
    !localStorage.getItem("realAmountData")
      ? ""
      : localStorage.getItem("realAmountData")
  );

  // localStorage.setItem("realAmountData", realAmount);

  const {
    usdtAmount,
    setUsdAmoutData,
    setUsdtAmount,
    buyTokens,
    isDialogOpen,
    redirect,
  } = useBuyToken();

  const { buyStatus } = useBuyStatus();

  const { totalSold } = useTotalSold();
  const { tokenPrice } = useTokenPrice();
  const [rangeSliderData, setRangeSliderData] = useState();
  const [price, setPrice] = useState();
  // const [isDialogOpen,setIsDialogOpen] = useState(false)

  const { claimTokens, claimingTokens } = useClaimToken();
  const { setActivePhase } = useSetActivePhase();
  const { startAirDrop } = useStartAirDrop();
  const { stopAirDrop } = useStopAirDrop();
  const { startBuy } = useStartBuy();
  const { stopBuy } = useStopBuy();
  const { buyInfo } = useBuyInfo();
  const [usdtData, setUsdtData] = useState("");

  const dataChange = (data) => {
    setUsdtData(data);
  };

  const connectMetamask = async () => {
    const provider = await detectEthereumProvider({
      mustBeMetaMask: true,
    });

    if (provider) {
      try {
        const accounts = await provider.request({
          method: "eth_requestAccounts",
        });
        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });
        if (chainId !== web3config.CHAIN) {
          try {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: web3config.CHAIN }],
            });
          } catch (switchError) {
            // setIsDialogOpen(false);
          }
        }

        const usdtContractData = web3config.usdtContract;
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(usdtAbi, usdtContractData);
        const balance = await contract.methods.balanceOf(accounts[0]).call();
        let realAmount = balance / 10 ** 6;

        localStorage.setItem("walletaddress", accounts[0]);
        localStorage.setItem("realAmountData", realAmount);

        setWalletAddress(accounts[0]);
        // loginWithbnbaddress(accounts[0]);

        // setIsOpen(false);
        toast.success("Wallet Connected!!");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } catch (err) {
        if (err.message.toString().split("Insufficient fund")[1]) {
          toast.error("Transaction reverted : " + err.message);
        } else {
          if (err.toString().split("execution reverted:")[1]) {
            toast.error(
              "Transaction reverted : " +
                err
                  .toString()
                  .split("execution reverted:")[1]
                  .toString()
                  .split("{")[0]
            );
          } else {
            toast.error(
              err.message ==
                "Already processing eth_requestAccounts. Please wait."
                ? "Please open your MetaMask as the transaction is already in progress."
                : err.message
            );
          }
        }
      }
    } else {
      toast.error("You need to install Metamask Wallet");
      setTimeout(() => {
        window.open("https://metamask.io/download/", "_blank");
      }, 1000);
    }
  };

  useEffect(() => {
    calculateTimeLeft();
    async function fetchData() {
      const info = await totalSold();

      setRangeSliderData(info);
      // ...
    }
    fetchData();

    // setBuyDetailss(info);
  }, []);

  useEffect(() => {
    async function fetchPrice() {
      const p = await tokenPrice();
      setPrice(p);
    }
    fetchPrice();
  }, []);

  let buttonText;
  if (claimingTokens) {
    buttonText = "Claiming Tokens...";
  } else {
    buttonText = "Claim Tokens";
  }

  if (redirect == true) {
    setTimeout(() => {
      window.location.href = `${config.baseUrl}Vesting`;
    }, 1000);
  }
  const handleBuy = async () => {
    // alert(walletaddres)
    // alert(loginData.address)

    // localStorage.setItem("usdtValue", usdt);

    console.log("walletaddres", walletaddres);
    console.log("loginData.address", loginData.address);
    if (loginData?.length == 0) {
      setTimeout(() => {
        window.location.href = `${config.baseUrl}userlogin`;
      }, 1000);
    } else if (walletaddres == "") {
      connectMetamask();
    } else if (walletaddres.toUpperCase() == loginData.address.toUpperCase()) {
      let data = await buyStatus(1);
      if (data == true) {
        console.log(usdtData, "usdtData");
        let remainingToken = parseFloat(500000) - parseFloat(rangeSliderData);

        if (
          parseFloat(0.8 * usdtData).toFixed(2) > parseFloat(remainingToken)
        ) {
          toast.error(
            `The maximum limit for LBT for sale is ${remainingToken}`
          );
          return;
        }
        if (parseFloat(usdtData) > parseFloat(realAmountData)) {
          toast.error("Insufficient fund");
          return;
        } else {
          buyTokens(realAmountData);
        }
      } else if (data == false) {
        toast.error("Buying is not Active for this Phase");
      } else {
        connectMetamask();
      }
    } else {
      toast.error("Please connect with your registered wallet Address");
    }
  };

  const maxValueDisplay = () => {
    //
    setUsdtAmount(realAmountData);
    dataChange(realAmountData);
  };
  //----------------------------------------------------------------- section for calculate the amount and Time  ----------------------------
  const getEndTime = () => {
    let endTime = localStorage.getItem("likebitCountdown");
    if (!endTime) {
      const startDate = new Date("2024-06-28");
      let endTime = startDate.getTime() + 100 * 24 * 60 * 60 * 1000; // 100 days from now
      localStorage.setItem("likebitCountdown", endTime);
    }
    return parseInt(endTime, 10);
  };
  const endTime = getEndTime();
  const starTime = new Date("2024-06-28").getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    if (now < starTime) {
      return {
        days: Math.floor((endTime - starTime) / (1000 * 60 * 60 * 24)),
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    const difference = endTime - new Date().getTime();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const [usdt, setUsdt] = useState("");
  const [lbt, setLbt] = useState("");

  const usdtValue = { price };
  const UsdtPrice = usdtValue.price;
  const handleUsdtChange = (e) => {
    const usdtValue = e.target.value;
    setTimeout(() => {
      setUsdAmoutData(e.target.value);
    });
    setUsdt(usdtValue);
    setLbt((usdtValue / UsdtPrice).toFixed(2));
  };

  const handleLbtChange = (e) => {
    const lbtValue = e.target.value;

    setLbt(lbtValue);
    setUsdt((lbtValue * UsdtPrice).toFixed(2));
  };

  const swapValues = () => {
    const tempUsdt = usdt;
    const tempLbt = lbt;
    setUsdt(tempLbt);
    //console.log((tempLbt / UsdtPrice).toFixed(2))
    setLbt(tempUsdt);
  };

  return (
    <>
      <Header />

      {/*------------- Banner Section Start -------------- */}
      <Toaster />

      {/* <div className='main_banner'> */}
      <Dialog
        title="Please Wait..."
        // icon="warning-sign"
        style={{
          color: "#3fa1f3",
          textAlign: "center",
        }}
        isOpen={isDialogOpen}
        isCloseButtonShown={false}
        className="modalBox"
      >
        <div className="text-center">
          {/* <BarLoader color="#e84747" height="2" /> */}
          <br />
          <h5 style={{ color: "#000" }}>
            Buying process in progress, once process completed Data will be show
            on vesting page.
          </h5>
          <p style={{ color: "#091f3f" }}>
            Please do not refresh page or close tab.
          </p>
          <div>
            <div className="spinner-border"></div>
          </div>
        </div>
      </Dialog>
      <section className="privatesale pt-5 pb-5 mb-5">
        <Container>
          <Row className="">
            <Col lg={12} md={12} xs={12} className="mx-auto mb-5">
              <div className="privateTimes">
                <div className="">
                  <h1 className="mb-4">Private Sale Phase Ends In</h1>
                  <Row className="">
                    <Col lg={6} md={12} xs={12} className="mx-auto mb-5">
                      <div className="timephase mb-3 w-100">
                        <ul>
                          <li>
                            <span>
                              {String(timeLeft.days).padStart(2, "0")}
                            </span>
                          </li>
                          <li>
                            <span>
                              {String(timeLeft.hours).padStart(2, "0")}
                            </span>
                          </li>
                          <li>
                            <span>
                              {String(timeLeft.minutes).padStart(2, "0")}
                            </span>
                          </li>
                          <li>
                            <span>
                              {String(timeLeft.seconds).padStart(2, "0")}
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div className="dayhours">
                        <ul>
                          <li>Days</li>
                          <li>Hours</li>
                          <li>Minutes</li>
                          <li>Seconds</li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col lg={6} md={12} xs={12} className="d-flex align-items-center">
              <Fade left>
                <div
                  className="getstart_block p-xl-5 p-lg-5 p-md-5 p-3"
                  style={{ minHeight: "500px" }}
                >
                  <div className="formbox px-lg-4 ">
                    <Form autoComplete="off">
                      <div className="brn_text">
                        <div
                          class="col-lg-12"
                          style={{
                            padding: "12px",
                            background: "transparent",
                          }}
                        >
                          <div class="">
                            <div class="text-center cardBox mt-5">
                              <h6 className="mb-3 fs-2">
                                1 LBT = {price} USDT
                              </h6>
                              <h1 class="text-gradient">
                                <h2>
                                  {!walletaddres ? (
                                    <Button
                                      onClick={(e) => connectMetamask()}
                                      variant=""
                                      style={{ border: "1px solid" }}
                                      type="button"
                                    >
                                      CONNECT WALLET
                                    </Button>
                                  ) : (
                                    // "CONNECT WALLET"
                                    "CONNECTED WALLET"
                                  )}
                                  <br />
                                </h2>
                                <h6
                                  className="my-4"
                                  style={{ fontSize: "small" }}
                                >
                                  {!walletaddres ? "" : walletaddres}
                                </h6>
                              </h1>

                              <div className="">
                                <Form.Group
                                  className="mb-3 position-relative"
                                  controlId="usdtInput"
                                >
                                  {!walletaddres ? (
                                    <Form.Control
                                      type="number"
                                      disabled
                                      placeholder="Enter USDT Amount"
                                      value={usdt}
                                      onChange={handleUsdtChange}
                                      style={{ paddingRight: "87px" }}
                                    />
                                  ) : (
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter USDT Amount"
                                      value={usdt}
                                      onChange={handleUsdtChange}
                                      style={{ paddingRight: "87px" }}
                                    />
                                  )}
                                  {loginData?.length == 0 ||
                                  walletaddres == "" ? (
                                    <></>
                                  ) : (
                                    <>
                                      <button
                                        type="button"
                                        class="fs-6 btn btn-primary-mainbtn1 mn-auto py-2 px-4 "
                                        onClick={maxValueDisplay}
                                        style={{
                                          position: "absolute",
                                          right: "5px",
                                          top: "5px",
                                        }}
                                      >
                                        Max
                                      </button>
                                    </>
                                  )}
                                </Form.Group>
                                <div className="mb-3">
                                  <Button
                                    variant="refreshbtn"
                                    type="button"
                                    className="px-4"
                                    onClick={swapValues}
                                  >
                                    <span>
                                      <MdOutlineSwapVert className="equalIcon" />
                                    </span>
                                  </Button>
                                </div>
                                <Form.Group
                                  className="mb-3"
                                  controlId="lbtInput"
                                >
                                  {!walletaddres ? (
                                    <Form.Control
                                      type="number"
                                      disabled
                                      placeholder="LBT Amount"
                                      value={lbt}
                                      onChange={handleLbtChange}
                                    />
                                  ) : (
                                    <Form.Control
                                      type="number"
                                      placeholder="LBT Amount"
                                      value={lbt}
                                      onChange={handleLbtChange}
                                    />
                                  )}
                                </Form.Group>
                              </div>
                              <div class=" mt-12 mb-12">
                                {/* {loginData?.length == 0 ? (
                                  ""
                                ) : (
                                  <input
                                    type="text"
                                    style={{ width: "80%" }}
                                    onKeyPress={(event) => {
                                      const { key, target } = event;
                                      const value = target.value;
                                      if (
                                        // If the pressed key is not a number, a decimal point, or already contains more than 4 digits after the decimal
                                        !/^\d{0,1}$/.test(key) &&
                                        key !== "."
                                      ) {
                                        event.preventDefault(); // Prevent the default action (typing)
                                      }
                                    }}
                                    className="form-control mb-4"
                                    value={usdtAmount}
                                    placeholder="Enter USDT Amouneeeet"
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // Regex pattern to allow numbers with up to four decimal places
                                      if (
                                        /^\d*\.?\d{0,4}$/.test(value) ||
                                        value === ""
                                      ) {
                                        setUsdtAmount(e.target.value); // Update input value state
                                        dataChange(e.target.value); // Call the additional function
                                      }
                                    }}
                                    // onChange={(e) => setUsdtAmount(e.target.value),dataChange(e.target.value)}
                                  />
                                )}{" "} */}
                                {loginData?.length == 0 ||
                                walletaddres == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    You Will Get : {lbt} LBT
                                    <br />
                                    Available USDT : {realAmountData}
                                  </>
                                )}
                                <br />
                                {loginData.length == 0}
                                <button
                                  type="button"
                                  onClick={handleBuy}
                                  class=" w-100 mb-3  fs-6 btn btn-primary-mainbtn1 mt-4"
                                >
                                  <b>Buy LBT</b>
                                </button>

                                {/* (
                                  <button
                                    type="button"
                                    style={{
                                      cursor:
                                        !usdtData || usdtData == 0
                                          ? "not-allowed"
                                          : "",
                                    }}
                                    disabled={!usdtData || usdtData == 0}
                                    onClick={handleBuy}
                                    class=" w-100 mb-3  fs-6 btn-primary-mainbtn1 mt-4"
                                  >
                                    <b>Buy LBT</b>
                                  </button>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </Fade>
            </Col>

            <Col lg={6} md={12} xs={12} className="d-flex align-items-center">
              <Zoom>
                {/* <div className="">
                  <img
                    src="assets/images/bnrImage.png"
                    alt="bnrImage"
                    className="bnrImage"
                  />
                </div> */}
                <div className="handcoin_box py-lg-5 py-md-5 py-3">
                  <img
                    src="assets/images/Handpresale.png"
                    alt="handcoin"
                    className="handcoin"
                  />
                </div>
              </Zoom>
            </Col>
          </Row>
        </Container>
      </section>
      {/* </div> */}
      <Fade bottom>
        <div className="traditional_box py-5">
          <Container>
            <Row>
              <Col lg={12} md={12}>
                {/* <p>
                  Traditional financial methods could resemble a congested
                  marketplace of misunderstandings, but{" "}
                  <a href="#"> with LikeBit, </a>we present a simplified path
                  where <a href="#">resolving issues </a>is as straightforward
                  as the lines of communication that support our ground-breaking
                  platform.
                </p> */}
              </Col>
              <Row className="mx-1">
                <p
                  className="missionbox rangebox2 d-flex justify-content-center align-items-center"
                  style={{
                    fontSize: "20px",
                    textDecoration: "none",
                    height: "100%",
                  }}
                >
                  The company undertakes that if the total investment from the
                  private sale phase does not exceed the minimum threshold of
                  €150,000, then the entire invested capital received, minus
                  transfer fees, will be returned to the accounts from which it
                  originated
                </p>
              </Row>
            </Row>
          </Container>
        </div>
      </Fade>

      {/*------------- Banner Section Exit -------------- */}

      {/*------------- Whychoose Section Start -------------- */}

      <section className="whychhose_sec">
        <Container>
          <Row>
            <Col lg={12} md={12}>
              <div className="headingbox">
                <h6 className="">LikeBit Progress</h6>

                <Rangeslider totalSold={rangeSliderData} />
              </div>
            </Col>
          </Row>
          <br />
          <br />
        </Container>
      </section>

      <section className="faq_sec py-5">
        <Container>
          <Row>
            <Col lg={7} md={7} xs={12} className="mb-5">
              <Slide left>
                <div className="headingbox">
                  <h6>FAQ</h6>
                  <p>Your Questions Answered</p>
                </div>
              </Slide>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={12} xs={12} className="">
              <Zoom>
                <div className="gen_question">
                  <ul>
                    <li className="active">
                      <a href="javascript:void(0)">
                        <img
                          src="assets/icons/Polygon 1.svg"
                          alt="Polygon 1"
                          className="polygon_icon"
                        />
                        <span>General Questions</span>
                      </a>
                    </li>
                    <li>
                      {/* <a href="#">
                        <img
                          src="assets/icons/Polygon 2.svg"
                          alt="Polygon 2"
                          className="polygon_icon"
                        />
                        <span>ICO Question</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src="assets/icons/Polygon 2.svg"
                          alt="Polygon 2"
                          className="polygon_icon"
                        />
                        <span>Tokens Sales</span>
                      </a>
                    </li>
                    <li className="mb-2">
                      <a href="#">
                        <img
                          src="assets/icons/Polygon 2.svg"
                          alt="Polygon 2"
                          className="polygon_icon"
                        />
                        <span>Clients Related</span>
                      </a> */}
                    </li>
                  </ul>
                </div>
              </Zoom>
            </Col>
            <Col lg="8" md="12" xs="12" className="ps-lg-5 ps-md-0">
              {/* <Slide right> */}
              <div className="solu_right ps-lg-5 ps-md-0">
                <Accordion defaultActiveKey="0" className="">
                  <Accordion.Item eventKey="0" className="">
                    <Accordion.Header className="py-3">
                      How do I get started with Likebit?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                      To embark on your Likebit journey, explore our website and
                      initiate the process by creating an account. Our
                      user-friendly guides will help you kickstart your
                      experience.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" className="">
                    <Accordion.Header className="py-3">
                      Is Likebit secure?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                      LikeBit is based on Etherium VM and netwrok and following
                      all the standars for safe decantrillized transactions.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="">
                    <Accordion.Header className="py-3">
                      What wallets are compatible with Likebit?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                      Metamask in first phase and we are aiming to add more in
                      the future.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3" className="">
                    <Accordion.Header className="py-3">
                      What makes Likebit unique?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                      LikeBit rewards the user and the businesses in an
                      equavalient way, so it's a win win situation, What also
                      makes it unique is the way the coin gets produced.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4" className="">
                    <Accordion.Header className="py-3">
                      Can I download the Likebit whitepaper?
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                      Yes you can download it or see it online!
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              {/* </Slide> */}
            </Col>
          </Row>
        </Container>
      </section>

      {/*------------- FAQ Section Exit -------------- */}

      {/* <Fade bottom> */}
      <Footer />
      {/* </Fade> */}
    </>
  );
};

export default Presale;
