import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../generalCSS/ourcoin.css";
import { Container, Row, Col, Button, Card, Form, Accordion } from 'react-bootstrap';
import Header from './header';
import Footer from './footer';
import Cookies from "js-cookie";
import config from "../config/config";


const Ourcoin = () => {
    const [loginData, setLoginData] = useState(
        !Cookies.get("likeBitUser") ? [] : JSON.parse(Cookies.get("likeBitUser"))
    );

    const redirectTo = (id) => {
        if (id == 1) {
            window.location.href = `${config.baseUrl}userlogin`
        }
        else if (id == 2) {
            window.location.href = `${config.baseUrl}vesting`
        }
    }
    return (
        <>
            <Header />


            {/*------------- Get Off Section Start -------------- */}

            <section className='getoff_sec py-5'>
                <Container>

                    {/* <Row> */}

                    {/* <Col lg={6} md={6} xs={12} className=' d-flex align-items-center'>
                            <div className='missionText'>
                                <h4 className='mb-3'>GET 25% OFF NOW</h4>
                                <h2 className='mb-3'>Buy & sell crypto easily</h2>
                                <p>Trade Bitcoin, Ethereum, USDT and other altcoins using our crypto trading app.</p>
                                <div className='pt-2'>
                                    {loginData.length == 0 ? (
                                        <Button variant="custombtn2" type="button" onClick={(e) => redirectTo(1)} className="px-5">Get started</Button>
                                    ) : (<Button variant="custombtn2" type="button" onClick={(e) => redirectTo(2)} className="px-5">Get started</Button>)}

                                </div>
                            </div>
                        </Col>*/}


                    {/* </Row> */}

                    <div className="twonew">
                        <Row className="align-items-center ">
                            <Col xl={12} lg={12} md={12} xs={12} >
                            <div class='customhead text-centre'>
                                    <center> <h2 className="mb-3">TWO NEW TOKENS TO CHANGE THE GAME</h2></center>
                                    </div>
                             </Col>
                            <Col xl={3} lg={3} md={12} xs={12} >
                                <div  className='coin text-center'>
                                    


                                    <img src="assets/images/likebit_flat.png" style={{
                                        // animation: 'rotateAndFlip2 15s linear infinite'
                                    }} alt="getoffImg" className='getoffImg3' />
                                 <div class='customhead text-centre'> 
                                    <h2 className="mb-3">LBT</h2>
                                    </div>


                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={12} xs={12} className="pe-lg-2">
                                <div class='customhead text-centre'>
                                    {/* <center> <h4 className="mb-3">TWO NEW TOKENS TO CHANGE THE GAME</h4></center>
                                    &nbsp; */}

                                    <div className='text-start'>
                                        <p style={{ fontSize: '20px',textAlign: 'center' }}>LikeBit strives to establish traditional shopping methods in the digital realm by bridging the gap between the physical and technological worlds through the creation of the two LikeBit cryptocurrencies, LBT and LBTS.
                                        While LBTS serves as the currency for transactions within the application, LBT is primarily created to generate the necessary capital for its full development through the mobile application.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={3} lg={3} md={12} xs={12} >
                                <div className='coin text-center'>

                                    <img
                                        src="assets/images/whitecoinnew.png"
                                        alt="getoffImg"
                                        className='getoffImg2'
                                    // style={{
                                    //     animation: 'rotateAndFlip 20s linear infinite'
                                    // }}
                                    />
                                    <div class='customhead text-centre'> 
                                    <h2 className="mb-3">LBTS</h2>
                                    </div>
                                </div>
                            </Col>


                            {/* <Col lg={6} md={6} xs={12} className=''>
                                <Row>
                                    <div className=''>
                                       
                                        <img
                                            src="assets/images/42557.jpg"
                                            alt="getoffImg"
                                            className='getoffImg2'
                                            style={{
                                                animation: 'rotateAndFlip 20s linear infinite'
                                            }}
                                        />
                                        <img src="assets/images/Group 13.png" style={{
                                            animation: 'rotateAndFlip2 15s linear infinite'
                                        }}  alt="getoffImg" className='getoffImg3' />
                                    

                                    </div>
                                </Row>
                            </Col> */}
                        </Row>


                        <Row>
                            <Col lg={12} md={12} xs={12} className='pt-2'>
                                <div className='px-2'>
                                    <Row>

                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        <center>
                                            <div className='pt-2'>
                                                {loginData.length == 0 ? (
                                                    <Button variant="custombtn2" type="button" className="px-5"> <a style={{color:"white"}} target="blank" href={`${config.baseUrl}userlogin`} > Get started</a></Button>
                                                ) : (<Button variant="custombtn2" type="button"  className="px-5"> <a href={`${config.baseUrl}userlogin`} style={{textDecoration:"none"}}> Get started</a></Button>)}

                                            </div>
                                        </center>
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        &nbsp;
                                        <center>
                                        <div class='customhead text-centre'> 
                                            <h2 className="pt-5">LBT: INVEST NOW - PARTICIPATE IN OUR FUTURE!</h2>
                                            </div></center>
                                        &nbsp;

                                        <p style={{ fontSize: '20px',textAlign:'center' }}>Take a step into the future with the new LikeBit token (LBT)! LBT is more than just a token, it's an opportunity to engage with the future of LikeBit and our community.
                                        LBT represents a means to support the company's growth and share in its future. 
                                        With each LBT purchase, you contribute to building a dynamic ecosystem that will shape the future of the digital economy.
                                        But that's not all! By investing in LBT, you unlock access to a variety of rewards and privileges that will last for the next 30 years
                                        With growth and prosperity opportunities spanning a 30-year horizon, LBT isn't merely a token, it's your gateway to a brighter tomorrow.
                                        Initial buyers in private sales and pre-sales will receive bonuses, making them not only buyers but also committed partners in this venture.
                                        So,become a part of our future. Invest in LBT today and help construct a better tomorrow!</p>

                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="pt-5">
                        <Row>
                            <center> 
                            <div class='customhead text-centre'> 
                                <h2>
                                INVESTOR BENEFITS <br /> PRIVATE SALE PHASE</h2>
                                </div></center>
                        </Row>
                        <br />
                        <Row>
                            <center>  <p style={{ fontSize: '20px' }}>The benefits for investors from funding the <br /> application in the private sale stage include:</p></center>
                        </Row>
                    </div>
                    {/* <br /> */}
                    <Row>

                        <Col lg={6} md={6} xs={12} className="mb-lg-0 mb-md-0 mb-4">
                            <div className="missionbox rangebox">
                                <p style={{ fontSize: '20px' }}>The company will distribute a percentage of its profits to investors every year according to the following table:</p>
                                <table className="table table-striped">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th>Year Range</th>
                                            <th>Profit Distribution (%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>2027 - 2033</td>
                                            <td>20%</td>
                                        </tr>
                                        <tr>
                                            <td>2034 - 2043</td>
                                            <td>15%</td>
                                        </tr>
                                        <tr>
                                            <td>2044 - 2053</td>
                                            <td>10%</td>
                                        </tr>
                                    </tbody>

                                </table>
                                <p style={{ fontSize: '20px' }}>Even in years where the company does not record profits or its profits are less than the following percentages, the distribution of LBT tokens it holds will still occur based on the following percentages:</p>
                                <div className="rangetable">
                                    <table className="table table-striped">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>Year Range</th>
                                                <th>Profit Distribution (%)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>2027 - 2033</td>
                                                <td>5%</td>
                                            </tr>
                                            <tr>
                                                <td>2034 - 2043</td>
                                                <td>4%</td>
                                            </tr>
                                            <tr>
                                                <td>2034 - 2053</td>
                                                <td>3%</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6} md={6} xs={12} className="mb-lg-0 mb-md-0 mb-4">
                            <div className="missionbox rangebox">
                                <p style={{ fontSize: '20px' }} className="pt-3"> Additionally, they will receive a 15% percentage of their initial investment in the second currency (LBTS).</p>
                                <br />
                                <br />
                                <p style={{ fontSize: '20px' }}> If the total initial investment is more than $2500, then investors gain an additional advantage. They will be able to increase their coins in the future and have the rewards of the first two stages.*
                                    *The coins purchased later will participate in an independent pool with a fixed yield of 5% for all years until 2053. The maximum number of coins that can participate is 1,000,000 coins. </p>
                                <br />
                                <br />
                                <p style={{ fontSize: '20px' }}> All LBT coin holders will have the privilege to mine coins (LBTS) in the application.</p>
                            </div>
                        </Col>

                    </Row>
                    <br />
                    &nbsp;
                    <Row>
                        <Col lg={12} md={12} xs={12} className='pt-5'>
                            <center>
                            <div class='customhead text-centre'> 
                                <h2>
                                3 SIMPLE STEPS TO INVEST
                                </h2>
                                </div></center>
                            <br />
                            <div className='registerDiv px-5'>
                                <Row>
                                    <Col lg={4} md={6} xs={12} className=''>
                                        <div className='registerbox'>
                                            <div className=''>
                                                <img src="assets/images/registerimg1.png" alt="registerimg1" className='registerIcons' />
                                            </div>
                                            <div className='ms-lg-4 ms-md-4'>
                                                <a href={`${config.baseUrl}userregister`}target="blank">
                                                    <h3 className=''>Register</h3>
                                                </a>
                                                <p>Register on our website.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6} xs={12} className=''>
                                        <div className='registerbox'>
                                            <div className=''>
                                                <img src="assets/images/registerimg2.png" alt="registerimg2" className='registerIcons' />
                                            </div>
                                            <div className='ms-lg-4 ms-md-4'>
                                                <a href={`${config.baseUrl}connectwallet`}target="blank">
                                                    <h3 className=''>Connect your wallet</h3>
                                                </a>
                                                <p>Connect your Metamask wallet.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6} xs={12} className=''>
                                        <div className='registerbox'>
                                            <div className=''>
                                                <img src="assets/images/registerimg3.png" alt="registerimg3" className='registerIcons' />
                                            </div>
                                            <div className='ms-lg-4 ms-md-4'>
                                                <a href={`${config.baseUrl}Presale`}target="blank">
                                                    <h3 class="">Invest</h3>
                                                </a>
                                                <p>Invest the capital you desire.</p>

                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row><p className="missionbox rangebox2 mx-2 py-5 " style={{ fontSize: '20px', textDecoration: 'none' }}>The company undertakes that if the total investment from the private sale phase does not exceed the minimum threshold of €150,000, then the entire invested capital received, minus transfer fees, will be returned to the accounts from which it originated</p></Row>
                </Container>
            </section >


            {/*------------- Get Off Section Exit -------------- */}



            {/*------------- Trade Sec Section Start -------------- */}


            {/* <section className='trade_sec py-5'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} xs={12} className=''>
                            <div className='beginnerRightbox'>
                                <img src="assets/images/tradeImg.png" alt="tradeImg" className='tradeImg pe-lg-5 mb-lg-0 mb-5 ' />
                            </div>
                        </Col>

                        <Col lg={6} md={6} xs={12} className='d-flex align-items-center'>
                            <div className='tradeText'>
                                <h2 className='mb-3'>What We Do</h2>
                                <h3 className='mb-3'>Trade crypto on your  terms Anywhere.</h3>
                                <p>Invest in Bitcoin, Ethereum, USDT, and other cryptocurrencies using our crypto trading app. The Bitcoin and cryptocurrency markets have experienced a surge in volume recently, making it an exciting time to become a trader.</p>
                                {/* <div>
                                    <Button variant="custombtn1" className=" px-5 ">Learn More</Button>
                                </div> */}
            {/* </div> 
                        </Col>
                    </Row>
                </Container>
            </section> */}


            {/*------------- Trade Section Exit -------------- */}


            {/*------------- Features Section Start -------------- */}

            {/* <section className='features_sec py-5'>
                <Container>
                    <Row>
                        <Col lg={10} md={10} xs={12} className='mb-5 mx-auto'>
                            <div className='customhead text-center'>
                                <h2 className='mb-3'>Features</h2>
                                <h3 className='mb-3'>How it works</h3>
                                <p>Cryptocurrency markets have seen an increase in volume in recent weeks, which is a great opportunity for new traders.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3} md={6} xs={12} className='mb-4'>
                            <div className='missionbox'>
                                <div className='text-center'>
                                    <img src="assets/images/downloadapp.png" alt="downloadapp" className='missionIcons' />
                                </div>
                                <h3 className='mb-3'>Download App</h3>
                                <p>Trade Bitcoin, Ethereum, USDT and other altcoins using our crypto trading app.</p>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} className='mb-4'>
                            <div className='missionbox'>
                                <div className='text-center'>
                                    <img src="assets/images/transparancy_icon.png" alt="transparancy_icon" className='missionIcons' />
                                </div>
                                <h3 className='mb-3'>Transparency</h3>
                                <p>Trade Bitcoin, Ethereum, USDT and other altcoins using our crypto trading app.</p>

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} className='mb-4'>
                            <div className='missionbox'>
                                <div className='text-center'>
                                    <img src="assets/images/experince_icon.png" alt="experince_icon" className='missionIcons' />
                                </div>
                                <h3 className='mb-3'>Experience Team</h3>
                                <p>Trade Bitcoin, Ethereum, USDT and other altcoins using our crypto trading app.</p>

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} className='mb-4'>
                            <div className='missionbox'>
                                <div className='text-center'>
                                    <img src="assets/images/security_icon.png" alt="security_icon" className='missionIcons' />
                                </div>
                                <h3 className='mb-3'>Security Guarantee</h3>
                                <p>Trade Bitcoin, Ethereum, USDT and other altcoins using our crypto trading app.</p>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}


            {/*------------- Features Section Exit -------------- */}





            <Footer />



        </>
    )
}

export default Ourcoin;
