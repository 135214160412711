import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from "../config/config";
import "../generalCSS/signup.css";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import toast, { Toaster } from "react-hot-toast";
import { forgetAction } from "../Action/user.action";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import Header from "./header";

const ForgetPassword = () => {
  const [data, setdata] = useState({
    email: "",
  });
  const [validationerror, setvalidationerror] = useState({
    emailerror: "",
  });
  const navigate = useNavigate();
  let { encryptemail } = useParams();

  useEffect(() => {
    console.log(encryptemail);
  }, []);

  //===========================  verify acccount=================

  const inputhandler = (e) => {
    const { name, value } = e.target;
    setdata({ ...data, [name]: value });
    setvalidationerror({ ...validationerror, [name]: "" });
  };

  const validations = () => {
    let isValid = true;
    const errors = {};
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!data.email) {
      errors.emailerror = "Enter your email id";
      isValid = false;
    } else if (!emailFormat.test(data.email)) {
      errors.emailerror = "Invalid email format";
      isValid = false;
    }

    setvalidationerror(errors);
    return isValid;
  };

  const forgetPasswordApi = async (e) => {
    e.preventDefault();
    console.log(validationerror);
    const isValid = validations();
    console.log(isValid);
    if (!isValid) {
      console.log("invalid form");
    } else {
      try {
        let res = await forgetAction(data);
        console.log(res, "res");
        if (res.success) {
          toast.success("We have sent a reset password link to your mail");

          setTimeout(() => {
            navigate(`${config.baseUrl}userlogin`);
          }, 1000);
        } else {
          toast.error(res.msg);
        }
      } catch (error) {
        console.log(error);
        toast.error("Something Went Wrong! Please Try Again");
      }
    }
  };

  return (
    <>
      <Header />
      <Toaster />
      <section className="signup_sec pt-5">
        <Container>
          {/* <Row>
                        <Col lg={6} md={6} xs={12}>
                            <img
                                src="assets/images/Likebit_Logo_Purple_.png"
                                alt="Logo"
                                className="main_logo"
                            />
                        </Col>
                    </Row> */}

          <Row className="align-items-center">
            <Col lg={12} md={12} xs={12}>
              <Row>
                <Col lg={6} md={12} xs={12} className="">
                  <div className="getstart_block p-xl-5 p-lg-5 p-md-5 p-3 forgotpassbox">
                    <div className="headbox">
                      <p className="">
                        Already have an account?
                        <span>
                          <Link
                            style={{ textDecoration: "underline" }}
                            to={`${config.baseUrl}userlogin`}
                          >
                            Sign In{" "}
                          </Link>
                        </span>
                      </p>
                    </div>

                    <div className="google_box">
                      <Row>
                        <Col
                          lg={10}
                          md={12}
                          className="px-xl-5 px-lg-0 px-md-2 my-4 mx-auto"
                        >
                          {/* <Button
                            variant=""
                            type="button"
                            className="googlebtn w-100"
                          > */}
                          {/* <span>
                              <img
                                src="assets/icons/Google.svg"
                                alt=" Google"
                                className=" Googleicon me-3"
                                style={{ width: "35px", height: "35px" }}
                              />
                            </span>
                            Sign in with Google */}
                          {/* <GoogleOAuthProvider
                              clientId="1018403538313-1u3n5j591qoqcq9pvt0fuvopd9irj0p0.apps.googleusercontent.com"
                              className="gmail"
                            >
                              <GoogleLogin
                                onSuccess={(credentialResponse) => {
                                  const credentialResponseDecode = jwtDecode(
                                    credentialResponse.credential
                                  );
                                  console.log(credentialResponseDecode);
                                  responseGoogle(credentialResponseDecode);
                                }}
                                onError={() => {
                                  console.log("Login Failed");
                                }}
                              />
                            </GoogleOAuthProvider> */}
                          {/* </Button> */}
                        </Col>
                      </Row>
                    </div>

                    {/* <div className="ortext my-3">
                      <span></span>
                      <p>Or</p>
                      <span></span>
                    </div> */}
                    {/* mt-5 */}
                    <div className="formbox px-lg-4 ">
                      <Form autoComplete="off">
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Control
                            type="email"
                            name="email"
                            onChange={inputhandler}
                            placeholder="Enter Email Id"
                            autoComplete="off"
                          />
                        </Form.Group>
                        <div
                          style={{ marginTop: "-15px" }}
                          className="validationerror"
                        >
                          {validationerror.emailerror}
                        </div>
                        <br />
                        <Row>
                          <Col lg={12} md={12} className=" mx-auto">
                            <Button
                              variant="custombtn2"
                              type="button"
                              className="px-4 py-lg-3 py-md-3 py-2 w-100"
                              onClick={forgetPasswordApi}
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </Col>

                <Col
                  lg={6}
                  md={12}
                  xs={12}
                  className="d-flex align-items-center"
                >
                  <div className="handcoin_box py-lg-5 py-md-5 py-3">
                    <img
                      src="assets/images/handcoin.png"
                      alt="handcoin"
                      className="handcoin"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ForgetPassword;
