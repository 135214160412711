import React, { useState } from 'react';
import Web3 from 'web3';
import web3config from '../web3config';
import abi from '../../abi/presaleABI.json';

const useStopBuy = () => {
  const stopBuy = async (phaseId) => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });

        const web3 = new Web3(window.ethereum);
        const preSaleContract = web3config.preSaleContract;
        const contract = new web3.eth.Contract(abi, preSaleContract);

        const accounts = await web3.eth.getAccounts();
        const userAddress = accounts[0];
        console.log('addr', userAddress);

        const data = contract.methods.stopBuy(phaseId);
        const trx = {
          from: userAddress,
          to: web3config.preSaleContract,
          gas: 210000,
          gasPrice: 20000000000,
          data: data.encodeABI(),
        };
        const receipt = await web3.eth.sendTransaction(trx);
        console.log('Transaction receipt:', receipt);
        console.log('Transaction hash:', receipt.transactionHash);
        // console.log('trx', trx);
        alert('Buying stopped successfully!');
      } catch (error) {
        console.log('Error:', error);
        alert('Error stopping buying. Please try again.');
      }
    } else {
      alert('Please install MetaMask to proceed.');
    }
  };
  return { stopBuy };
};

export { useStopBuy };
