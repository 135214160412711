import React, { useState } from 'react';
import Web3 from 'web3';
import web3config from './web3config';
import presaleAbi from '../abi/presaleABI.json';
import toast, { Toaster } from 'react-hot-toast';
import { error } from 'jquery';

const useClaimToken = () => {
  const [claimingTokens, setClaimaingTokens] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [phaseId, setPhaseId] = useState();
  const claimTokens = async (id, index) => {
    console.log('idddd', index);
    if (window.ethereum) {
      try {
        if ((index = 'undefined')) {
          index = 0;
        }
        setIsDialogOpen(true);
        await window.ethereum.request({ method: 'eth_requestAccounts' });

        const web3 = new Web3(window.ethereum);
        const preSaleContractAddress = web3config.preSaleContract;
        const contract = new web3.eth.Contract(
          presaleAbi,
          preSaleContractAddress
        );
        const accounts = await web3.eth.getAccounts();
        const userAddress = accounts[0];

        const data = await contract.methods.claimToken(id, index);

        let gasPrice = await web3.eth.getGasPrice();
        let gasLimit = web3.utils.toHex(
          await web3.eth.estimateGas({
            to: preSaleContractAddress,
            from: userAddress,
            data: data.encodeABI(),
          })
        );

        const trx = {
          from: userAddress,
          to: preSaleContractAddress,
          gasPrice: web3.utils.toHex(gasPrice),
          gasLimit: gasLimit,
          data: await data.encodeABI(),
        };
        setClaimaingTokens(true);
        const receipt = await web3.eth.sendTransaction(trx);

        //
        toast.success('Tokens claimed successfully!');
        setIsDialogOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } catch (err) {
        setIsDialogOpen(false);
        if (err.message.toString().split('Insufficient fund')[1]) {
          toast.error('Transaction reverted : ' + err.message);
        } else {
          if (err.toString().split('execution reverted:')[1]) {
            toast.error(
              'Transaction reverted : ' +
                err
                  .toString()
                  .split('execution reverted:')[1]
                  .toString()
                  .split('{')[0]
            );
          } else {
            toast.error(err.message);
          }
        }
      }
    } else {
      toast.error('Please install MetaMask to proceed.');
    }
  };
  return { phaseId, setPhaseId, claimTokens, claimingTokens, isDialogOpen };
};
export { useClaimToken };
