import React, { useState } from 'react';
import Web3 from 'web3';
import web3config from './web3config';
import presaleAbi from '../abi/presaleABI.json';

const useTokenPrice = () => {
  const tokenPrice = async () => {
    try {
      // await window.ethereum.request({ method: 'eth_requestAccounts' });

      const web3 = new Web3(web3config.alchemyURL);
      const preSaleContract = web3config.preSaleContract;
      const contract = new web3.eth.Contract(presaleAbi, preSaleContract);

      const accounts = await web3.eth.getAccounts();
      //      const userAddress = accounts[0];

      const infooo = await contract.methods.getTokenPrice().call();
      let info = infooo / 10 ** 6;
      return info;
      // console.log("Phase Details", infooo/10**18);
    } catch (error) {
      console.log('Error:', error.msg);
      if (error.msg == undefined) {
        return;
      } else {
        alert('Error getting details. Please try again.');
      }
    }
  };

  return { tokenPrice };
};

export { useTokenPrice };
