import React, { useEffect, useState } from "react";
import config from "../config/config";
import "../generalCSS/selectwallet.css";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import toast, { Toaster } from "react-hot-toast";
import Header from "./header";
import usdtAbi from "../abi/usdtABI.json";
import web3config from "../components/web3config";
//import { isMobile } from "react-device-detect"

const Selectwallet = () => {
  const [walletaddres, setWalletAddress] = useState(
    !localStorage.getItem("walletaddress")
      ? ""
      : localStorage.getItem("walletaddress")
  );
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const connectMetamask = async () => {
    const provider = await detectEthereumProvider({
      mustBeMetaMask: true,
    });

    if (provider) {
      try {
        const accounts = await provider.request({
          method: "eth_requestAccounts",
        });
        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });
        if (chainId !== web3config.CHAIN) {
          try {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: web3config.CHAIN }],
            });
          } catch (switchError) {
            // setIsDialogOpen(false);
          }
        }

        const usdtContractData = web3config.usdtContract;
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(usdtAbi, usdtContractData);
        const balance = await contract.methods.balanceOf(accounts[0]).call();
        let realAmount = balance / 10 ** 6;

        localStorage.setItem("walletaddress", accounts[0]);
        localStorage.setItem("realAmountData", realAmount);

        setWalletAddress(accounts[0]);
        loginWithbnbaddress(accounts[0]);

        setIsOpen(false);
        toast.success("Wallet Connected!!");
      } catch (err) {
        if (err.message.toString().split("Insufficient fund")[1]) {
          toast.error("Transaction reverted : " + err.message);
        } else {
          if (err.toString().split("execution reverted:")[1]) {
            toast.error(
              "Transaction reverted : " +
                err
                  .toString()
                  .split("execution reverted:")[1]
                  .toString()
                  .split("{")[0]
            );
          } else {
            toast.error(
              err.message ==
                "Already processing eth_requestAccounts. Please wait."
                ? "Please open your MetaMask as the transaction is already in progress."
                : err.message
            );          }
        }
      }
    } else {
      toast.error("You need to install Metamask Wallet");
      setTimeout(() => {
        window.open("https://metamask.io/download/", "_blank");
      }, 1000);
    }
  };

  const loginWithbnbaddress = async (id) => {
    // let res = await LoginWithAddressAction({ address: id });
    // if (res.success) {
    //   toast.success(res.msg);
    //   Cookies.set("loginSuccessMaxLife", JSON.stringify(res.data));
    setTimeout(() => {
      window.location.href = `${config.baseUrl}Presale`;
    }, 1000);
    // } else {
    //   toast.error(res.msg);
    // }
  };

  // const handleConnect = async () => {
  //   if(isMobile) {
  //     const chainId = await window.ethereum.request({
  //       method: "eth_chainId",
  //     });
  //     if (chainId !== web3config.CHAIN) {
  //       try {
  //         await window.ethereum.request({
  //           method: "wallet_switchEthereumChain",
  //           params: [{ chainId: web3config.CHAIN }],
  //         });
  //       } catch (switchError) {
  //         // setIsDialogOpen(false);
  //       }
  //     }
  //     window.location.href = `https://metamask.app.link/dapp/${config.baseUrl}Presale`
  //   }  else {
  //     connectMetamask()
  //   }
  // }

  return (
    <>
      <Toaster />

      {/* <section className="pt-4">
        <Container>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Link to={`${config.baseUrl}`}>
                <img
                  src="assets/images/Likebit_Logo_Purple_.png"
                  alt="Logo" style={{width:"145px"}}
                  className="main_logo"
                />
              </Link>
            </Col>
          </Row>
        </Container>
      </section> */}
      <Header />
      <section className="selectwallte_sec pt-5">
        <Container>
          {/* <Row>
            <Col lg={6} md={6} xs={12}>
              <img
                src="assets/images/Likebit_Logo_Purple_.png"
                alt="Logo"
                className="main_logo"
              />
            </Col>
          </Row> */}

          <Row>
            <Col lg={12} md={12} xs={12}>
              <Row>
                <Col lg={6} md={12} xs={12} className="">
                  <div className="getstart_block p-xl-5 p-lg-5 p-md-5 p-3 d-flex justify-content-center flex-column scanwalletbox">
                    <div className="headbox">
                      <h1>Select Wallet</h1>
                    </div>

                    <div className="google_box">
                      <Row>
                        <Col
                          lg={10}
                          md={12}
                          className="px-xl-5 px-lg-0 px-md-2 mt-4 mx-auto"
                        >
                          <Button
                            onClick={connectMetamask}
                            variant=""
                            type="button"
                            className="googlebtn w-100"
                          >
                            <span>
                              <img
                                src="assets/icons/metamask.svg"
                                alt=" metamask"
                                className=" Googleicon me-3"
                                style={{ width: "35px", height: "35px" }}
                              />
                            </span>
                            Metamask
                          </Button>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col
                          lg={10}
                          md={12}
                          className="px-xl-5 px-lg-0 px-md-2 mt-4 mx-auto"
                        >
                          <Button
                            variant=""
                            type="button"
                            className="googlebtn w-100"
                          >
                            <span>
                              <img
                                src="assets/icons/walleticon.svg"
                                alt=" walleticon"
                                className=" Googleicon me-3"
                                style={{ width: "35px", height: "35px" }}
                              />
                            </span>
                            Wallet Connect
                          </Button>
                        </Col>
                      </Row> */}
                    </div>
                  </div>
                </Col>

                <Col
                  lg={6}
                  md={12}
                  xs={12}
                  className="d-flex align-items-center"
                >
                  <div className="handcoin_box py-5">
                    <img
                      src="assets/images/handcoin.png"
                      alt="handcoin"
                      className="handcoin"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Selectwallet;
