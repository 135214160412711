import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../generalCSS/about.css";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Accordion,
} from "react-bootstrap";
import Header from "./header";
import Footer from "./footer";
import CountUp from "react-countup";

const About = () => {
  return (
    <>
      <Header />

      {/*------------- Our Story Section Start -------------- */}

      <section className="story_sec py-5">
        <Container>
          <Row>
            <Col lg={12} md={12} xs={12} className="mb-2 mx-auto">
              <div className="customhead text-center">
                <h2 className="mb-3">Our Story</h2>
                <h3 className="mb-3">About our company</h3>
              </div>
              <p className=" text-center paratext">
                LikeBit, established in 2023 in Greece, is a pioneering endeavor in the domain of digital currency and blockchain technology. Powered by the software house, Web Infamous, LikeBit aims to revolutionize the landscape of digital transactions and decentralized finance.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} xs={12} className="mx-auto">
              <div className="missionbox card2">
                <img
                  src="assets/images/clientImg.png"
                  alt="aboutImg"
                  className="aboutImg"
                />
               
              </div>
              <br />
            </Col>
            <Col lg={6} md={6} xs={12} className="mx-auto d-flex align-items-center">
              <div className="" >
                <p className="paratext"> Based in Alexandroupoli, LikeBit was founded by Dimitrios Alchatib, who serves as its CEO. With a background in finance, accounting, and insurance consultancy, Dimitris brings extensive knowledge and aims to redefine society's relationship with cryptocurrencies. Leading a dedicated team, Dimitris is committed to developing innovative solutions tailored to the dynamic landscape of the digital economy.</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} xs={12} className="mx-auto d-flex align-items-center">
              <div className="">
                <p className="paratext">  Dimitrios Chitas, the Technical Director, leverages his rich background in software development and blockchain technology to deliver bespoke solutions for LikeBit. Under his guidance, the company remains committed to ongoing innovation and furnishing users with state-of-the-art applications and services</p>
              </div>
            </Col>
            <Col lg={6} md={6} xs={12} className="mx-auto ps-0">
              <div className="missionbox card2">
                <img
                  src="assets/images/clientImg2.jpeg"
                  alt="aboutImg"
                  className="aboutImg2"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/*------------- Our Story Section Exit -------------- */}

      <section className="mission_sec">
        <Container>
          <Row>
            <Col lg={12} md={12} xs={12} className="mx-auto d-flex align-items-center">
              <div className="">
                <p className="paratext" >
                In 2024, LikeBit is set to unveil its proprietary token, marking a significant stride in its mission to empower individuals and enterprises with streamlined, secure, and efficient digital transactions. Anchored by a steadfast dedication to transparency,innovation, and user-centricity, LikeBit is poised to redefine the financial landscape both locally and globally</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/*------------- Our Mission Section Start -------------- */}

      <section className="mission_sec py-5">
        <Container>
          <div className='missionbox'>
            <Row >
              <Col lg={10} md={10} xs={12} className=" mx-auto">
                <div className="customhead text-center">
                  <h2 className="mb-3">Our Mission</h2>
                  {/* <h3 className="mb-3">
                    We are helping people to get a success.
                  </h3> */}
                  <p>
                    LikeBit aims to revolutionize the digital currency and blockchain landscape by bridging the gap between the real economy and cryptocurrencies. Our platform empowers individuals and businesses with efficient digital transactions and rewarding opportunities. Committed to transparency, innovation, and a user and business focus, LikeBit seeks to redefine interactions with digital currencies, promoting economic prosperity and growth worldwide.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <br />
          <Row>
            <Col lg={4} md={6} xs={12} className="mb-4">
              <div className="missionbox card">
                <div className="text-center">
                  <img
                    src="assets/images/transparancy_icon.png"
                    alt="transparancy_icon"
                    className="missionIcons"
                  />
                </div>
                <h3 className="mb-3">Transparency</h3>
                <p>
                  At LikeBit, transparency is at the core of our operational philosophy. We prioritize openness and clarity, especially in our reward distribution process. That's why we've selected the Ethereum Blockchain for our operations—a globally recognized and dependable network
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} xs={12} className="mb-4">
              <div className="missionbox card">
                <div className="text-center">
                  <img
                    src="assets/images/experince_icon.png"
                    alt="experince_icon"
                    className="missionIcons"
                  />
                </div>
                <h3 className="mb-3">Experienced Team</h3>
                <p>
                  Backed by a team with extensive experience in both cryptocurrency and digital advertising, we are committed to delivering a seamless and rewarding user experience. Our tech gurus leverage their expertise to optimize the platform for maximum engagement, ensuring that every interaction is meaningful and rewarding.
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} xs={12} className="mb-4">
              <div className="missionbox card">
                <div className="text-center">
                  <img
                    src="assets/images/security_icon.png"
                    alt="security_icon"
                    className="missionIcons"
                  />
                </div>
                <h3 className="mb-3">Security Guarantee</h3>
                <p>
                  LikeBit holds the utmost regard for the confidentiality and integrity of our users' data and assets, meticulously fortifying our platform with robust security measures to fend off any potential threats or vulnerabilities. Thus, users know that state-of-the-art security protocols shield their information and rewards and that they can safely navigate the digital landscape
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/*------------- Our Mission Section Exit -------------- */}

      {/*------------- Our Millions Section Start -------------- */}
      <section className="beginners_sec py-5">
        <Container>
          <Row>
            <Col lg={6} md={6} xs={12} className=" ps-lg-5">
              <div className="missionText">
                {/* <h3 className="mb-3"></h3> */}
                <h2 className="mb-3">
                  The Idea Behind Creating Cryptocurrencies
                </h2>
                <p>
                  We are excited to introduce you to our unique application, which is set to revolutionize interactions within digital and real-world economies. By viewing our video, you'll gain insights into the motivations behind our cryptocurrency creation. Don't miss the chance to be part of this groundbreaking initiative! <a href="http://likebit.io/"target="blank" style={{color:'#353535'}}>Read More</a>
                </p> 

              </div>
            </Col>

            <Col lg={6} md={6} xs={12} className="d-flex align-items-center">
              {/* <div className="beginnerRightbox pt-lg-0 pt-5">
                <img
                  src="assets/images/beginnerImg.png"
                  alt="beginnerImg"
                  className="beginnerImg"
                />
                <div>
                  <a href="#" className="">
                    <img
                      src="assets/icons/playicon.svg"
                      alt="playicon"
                      className="playicon"
                    />
                    

                  </a>
                </div>
              </div> */}
              {/* <div className="beginnerRightbox"> */}

              <video loop  controls className="videoDiv">
                <source src="assets/images/webinfamous-delivery-4.mp4" type="video/mp4" />
              </video>
              {/* </div> */}
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="millions_sec py-5">
        <Container>
          <Row>
            <Col lg={6} md={6} xs={12} className="mb-5">
              <div className="">
                <img
                  src="assets/images/millionsImg.png"
                  alt="millionsImg"
                  className="millionsImg"
                />
              </div>
              <Row>
                <Col lg={12} md={12} xs={12} className="">
                  <div className=" missionText">
                    <h3 className="mb-3">Transparency</h3>
                    <p>
                      At LikeBit, transparency is the cornerstone of our operations. We adhere to principles of openness, particularly in our reward distribution process, and use the Ethereum Blockchain, a globally recognized and reliable network.
                    </p>

                    <h3 className="mb-3">Experience Team</h3>
                    <p>
                      Our team, with vast experience in cryptocurrency and digital advertising, is committed to delivering a seamless and rewarding user experience. Our experts optimize the platform for maximum engagement, ensuring each interaction is meaningful
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={6} xs={12} className=" ps-lg-5 ">
              <div className="missionText">
                <h2 className="mb-3">Our Mission</h2>
                
                <p style={{ textAlign: "justify" }}>
                  LikeBit aims to revolutionize the digital currency and blockchain landscape by bridging the gap between the real economy and cryptocurrencies. Our platform empowers individuals and businesses with efficient digital transactions and rewarding opportunities. Committed to transparency, innovation, and a user and business focus, LikeBit seeks to redefine interactions with digital currencies, promoting economic prosperity and growth worldwide.

                </p>

                <h3 className="mb-3">Security Guarantee</h3>

                <p style={{ textAlign: "justify" }}>
                  LikeBit places utmost importance on the confidentiality and integrity of user data and assets, fortifying our platform with robust security measures to fend off potential threats. Users can rest assured that their information and rewards are protected by the latest security protocols, enabling safe navigation through the digital landscape
                </p>

              </div>
              <div className="missionText">

                <h3 className="mb-3">The Idea Behind Creating Cryptocurrencies</h3>
                
                <p style={{ textAlign: "justify" }}>
                  We are excited to introduce you to our unique application, which is set to revolutionize interactions within digital and real-world economies. By viewing our video, you'll gain insights into the motivations behind our cryptocurrency creation. Don't miss the chance to be part of this groundbreaking initiative! Click
                </p>

              </div>
            </Col>
          </Row>

          <Row style={{display:"none"}}>
            <Col xl={10} lg={12} md={12} xs={12} className="mx-auto mt-5">
              <Row>
                <Col lg={4} md={4} xs={12} className="pe-lg-5 mb-4">
                  <div className="counter_box">
                    <div>
                      <span className="num">
                        <CountUp end={18} enableScrollSpy />
                      </span>
                      <span className="smallcount_text">K</span>
                    </div>
                    <h6 className>Registered investors</h6>
                  </div>
                </Col>
                <Col lg={4} md={4} xs={12} className="px-lg-4 mb-4">
                  <div className="counter_box">
                    <div>
                      <span className="num">
                        <CountUp end={150} enableScrollSpy />
                      </span>
                      <span className="smallcount_text">K</span>
                    </div>
                    <h6 className>Total transactions</h6>
                  </div>
                </Col>
                <Col lg={4} md={4} xs={12} className="ps-lg-5 mb-4">
                  <div className="counter_box">
                    <div>
                      <span className="num">
                        <CountUp end={132} enableScrollSpy />
                      </span>
                      <span className="smallcount_text"></span>
                    </div>
                    <h6 className>Total users</h6>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section> */}

      {/*------------- Our Millions Section Exit -------------- */}

      {/*------------- Beginners Section Start -------------- */}

      {/* <section className="beginners_sec py-5">
        <Container>
          <Row>
            <Col lg={6} md={6} xs={12} className=" ps-lg-5">
              <div className="missionText">

                 <p>
                 We are excited to introduce you to our unique application, which is set to revolutionize interactions within digital and real-world economies. By viewing our video, you'll gain insights into the motivations behind our cryptocurrency creation. Don't miss the chance to be part of this groundbreaking initiative! Click
                </p> 

              </div>
            </Col>

            <Col lg={6} md={6} xs={12} className="">
              <div className="beginnerRightbox pt-lg-0 pt-5">
                <img
                  src="assets/images/beginnerImg.png"
                  alt="beginnerImg"
                  className="beginnerImg"
                />
                <div>
                  <a href="#" className="">
                    <img
                      src="assets/icons/playicon.svg"
                      alt="playicon"
                      className="playicon"
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>  */}

      {/*------------- Beginners Section Exit -------------- */}

      <Footer />
    </>
  );
};

export default About;
