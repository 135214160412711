import React from 'react';
import { Link } from 'react-router-dom';
import config from '../config/config';
import "../generalCSS/scanwallet.css";
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import { IoEyeOutline } from "react-icons/io5";

const Scanwallet = () => {
    return (
        <>

            <section className='pt-4'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} xs={12}>
                            <Link to={`${config.baseUrl}`}>
                                <img
                                    src="assets/images/Likebit_Logo_Purple_.png"
                                    alt="Logo" style={{width:"145px"}}
                                    className="main_logo"
                                />
                            </Link>
                        </Col>
                    </Row>

                </Container>
            </section>


            <section className='scanwallet pt-5'>
                <Container>
                    {/* <Row>
            <Col lg={6} md={6} xs={12}>
              <img
                src="assets/images/Likebit_Logo_Purple_.png"
                alt="Logo"
                className="main_logo"
              />
            </Col>
          </Row> */}

                    <Row>
                        <Col lg={12} md={12} xs={12}>
                            <Row>
                                <Col lg={6} md={12} xs={12} className=''>
                                    <div className='getstart_block p-xl-5 p-lg-5 p-md-5 p-3 d-flex justify-content-center flex-column'>
                                        <div className='text-center'>
                                            <img
                                                src="assets/images/scanimg.png"
                                                alt="scanimg"
                                                className="scanimg"
                                            />

                                        </div>
                                        <div className='headbox mt-4'>
                                            <h3>Scan to connect your wallet</h3>
                                        </div>

                                        <div className='ortext my-3'>
                                            <span></span>
                                            <p>Or</p>
                                            <span></span>
                                        </div>

                                        <div className='formbox px-4'>
                                            <Form autoComplete="off">
                                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                                    <Form.Label>Enter the wallet address</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter Wallet address" autoComplete="off" />
                                                </Form.Group>
                                            </Form>


                                        </div>

                                    </div>
                                </Col>


                                <Col lg={6} md={12} xs={12} className='d-flex align-items-center'>
                                    <div className='handcoin_box py-5'>
                                        <img
                                            src="assets/images/handcoin.png"
                                            alt="handcoin"
                                            className="handcoin"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Scanwallet;
