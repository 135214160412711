import React, { useEffect, useState } from "react";
import { Range, getTrackBackground } from "react-range";

const STEP = 1;
const MIN = 0;
const MAX = 2000000;
const Rangeslider = ({ totalSold }) => {
  const [values, setValue] = useState([totalSold]);
  useEffect(() => {
    setValue([totalSold]);
  }, [totalSold]);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <div className="coundownText">
        <output
  
          className="fs-3 fw-bold text-blue"
          id="output"
        >
          The count down is on : {values[0] ? parseFloat(values[0]).toFixed(4) : 0} LBT sold
          {/* Total no of tokens sold&nbsp;:&nbsp;{parseFloat(values[0]).toFixed(4)} */}
        </output>
        </div>
        {values[0] ?
          <Range
            disabled={true}
            values={values}
            step={STEP}
            min={MIN}
            max={MAX}
            onChange={(values) => setValue([values])}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "36px",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "15px",
                    width: "100%",
                    borderRadius: "4px",
                    background: getTrackBackground({
                      values: values,
                      colors: ["#2db95f", "#3d3d3d"],
                      min: MIN,
                      max: MAX,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              <div className="smallLogoBox"
                {...props}
                style={{
                  ...props.style,
                  height: "60px",
                  width: "60px",
                  borderRadius: "50px",
                  background:
                    "url(assets/images/logosmallIcon.png) 0% 0% / 100% #000",
                  border: "1px solid rgb(35 34 34)",
                  backgroundRepeat: "no-repeat",
                  // backgroundColor: "#1DCCFA",
                  display: "flex",
                  outline: "none",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: "16px",
                    width: "5px",
                    background: isDragged ? "" : "",
                  }}
                />
              </div>
            )}
          /> : ""
        }

      </div>
    </>
  );
};

export default Rangeslider;
