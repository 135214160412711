import React, { useState } from 'react';
import Web3 from 'web3';
import web3config from './web3config';
import abi from '../abi/presaleABI.json';
import toast, { Toaster } from "react-hot-toast";

const useBuyInfo = () => {
  const buyInfo = async () => {
    if (window.ethereum) {
      try {
        
        await window.ethereum.request({ method: 'eth_requestAccounts' });

        const web3 = new Web3(window.ethereum);
        const preSaleContract = web3config.preSaleContract;
        const contract = new web3.eth.Contract(abi, preSaleContract);

        const accounts = await web3.eth.getAccounts();
        const userAddress = accounts[0];

        const info = await contract.methods
          .getUserPurchaseDetails(userAddress)
          .call();
        return info;
        // localStorage.setItem("BuyDetails", JSON.stringify(info));

      } catch (err) {
        if (err.message.toString().split("Insufficient fund")[1]) {
          toast.error("Transaction reverted : " + err.message);
        } else {
          if (err.toString().split("execution reverted:")[1]) {
            toast.error(
              "Transaction reverted : " +
                err
                  .toString()
                  .split("execution reverted:")[1]
                  .toString()
                  .split("{")[0]
            );
          } else {
            toast.error(
              err.message ==
                "Already processing eth_requestAccounts. Please wait."
                ? "Please open your MetaMask as the transaction is already in progress."
                : err.message
            );          }
        }
      }
    } else {
      alert('Please install MetaMask to proceed.');
    }
  };

  return { buyInfo };
};

export { useBuyInfo };
