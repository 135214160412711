import React from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";

import config from '../config/config';
import "../generalCSS/connectwallet.css";
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import { IoEyeOutline } from "react-icons/io5";
import Header from "./header";

const Connectwallet = () => {
    const navigate = useNavigate();

    const redirectToWallet = () => {
        
        navigate(`${config.baseUrl}Selectwallet`);

    }
    return (
        <>
            {/* <section className='pt-4'>
                <Container>
                    <Row>
                        <Col lg={6} md={6} xs={12}>
                            <Link to={`${config.baseUrl}`}>
                                <img
                                    src="assets/images/Likebit_Logo_Purple_.png"
                                    alt="Logo" style={{width:"145px"}}
                                    className="main_logo"
                                />
                            </Link>
                        </Col>
                    </Row>

                </Container>
            </section> */}
            <Header/>

            <section className='connetwallet_sec pt-5'>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={12} md={12} xs={12}>
                            <Row>
                                <Col lg={6} md={12} xs={12} className=''>
                                    <div className='getstart_block p-xl-5 p-lg-5 p-md-5 p-3'>
                                        <div className=''>
                                            <img
                                                src="assets/images/walletimg.png"
                                                alt="walletimg"
                                                className="walletimg"
                                            />
                                        </div>
                                        <div className='headbox mt-5 mt-0'>
                                            <h1>Connect Your Wallet</h1>
                                            <div className='text-center my-4'>
                                                <a href='#' className='remind'>Remind me later</a>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col xl={6} lg={12} md={12} className=' mx-auto'>
                                                {/* <Button variant="" type="button" className='custombtn w-100'>
                                                    Connect wallet
                                                </Button> */}
                                                <Button variant="custombtn2" onClick={redirectToWallet} type="button" className='px-4 py-3 w-100'>
                                                    Connect wallet
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>


                                <Col lg={6} md={12} xs={12} className='d-flex align-items-center'>
                                    <div className='handcoin_box py-5'>
                                        <img
                                            src="assets/images/handcoin.png"
                                            alt="handcoin"
                                            className="handcoin"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default Connectwallet;
