// module.exports = {
//   preSaleContract: '0x15b44aF38dd5fb97D9AEE92b4030937a7ca83F31',
//   usdtContract: '0xf73D7434E11a311a1Ed83eC85449043e801E48a8',
//   LBTcontract: '0xFe7bf8871B1a4C4e291433f478460691FFc6de7D',
//   CHAIN: '0xAA36A7',
//   testnetURL: 'https://sepolia.infura.io/v3/',
// };

module.exports = {
  preSaleContract: '0xB0e4c30E087daeB754E887dD3F612D21045E9C83',
  // preSaleContract: "0x15b44aF38dd5fb97D9AEE92b4030937a7ca83F31", //Aman
  // LBTcontract: "0xFe7bf8871B1a4C4e291433f478460691FFc6de7D", //Aman
  // usdtContract: "0xf73D7434E11a311a1Ed83eC85449043e801E48a8",
  LBTcontract: '0x490C52A674E8421779816af4712C5caef72e7464',
  usdtContract: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  CHAIN: '0x1',
  //testnetURL: 'https://sepolia.infura.io/v3/',
  mainnetURL: 'https://mainnet.infura.io/v3/',
  alchemyURL:
    'https://eth-mainnet.g.alchemy.com/v2/apiAN-RQ2wAM9VUPoZqcl5PDb0ByF6jJ',
};
