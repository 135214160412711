import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import config from "../config/config";
import "../generalCSS/header.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Cookies from "js-cookie";
import Web3 from "web3";
import usdtAbi from "../abi/usdtABI.json";
import web3config from "../components/web3config";
import { Dropdown } from "react-bootstrap";
const Header = () => {
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState(
    !Cookies.get("likeBitUser") ? [] : JSON.parse(Cookies.get("likeBitUser"))
  );
  const [walletaddres, setWalletAddress] = useState(
    !localStorage.getItem("walletaddress")
      ? ""
      : localStorage.getItem("walletaddress")
  );
  const [segment, setSegment] = useState("");
  useEffect(() => {
    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash
    setSegment(lastSegment);


    if (loginData?.length == 0) {
      return;
    } else {
      async function fetchData() {
        // You can await here
        const usdtContractData = web3config.usdtContract;
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(usdtAbi, usdtContractData);
        const balance = await contract.methods.balanceOf(walletaddres).call();
        let realAmount = balance / 10 ** 6;
        // console.log('realAmount',realAmount)
        localStorage.setItem("realAmountData", realAmount);
        // ...
      }


      fetchData();
    }
  }, [walletaddres]);
  const logoutAll = () => {
    Cookies.remove("likeBitUser");
    Cookies.remove("emailShow");
    localStorage.removeItem("walletaddress");
    localStorage.removeItem("realAmountData");
    localStorage.removeItem('reloadData');

    setTimeout(() => {
      window.location.href = `${config.baseUrl}`;
    }, 1000);
  };

  const redirectTo = () => {
    setTimeout(() => {
      window.location.href = `${config.baseUrl}`;
    }, 500);
  };
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    if (!isOpen && dropdownRef.current) {
      dropdownRef.current.click();
    }
  };

  const handleToggle = (nextOpenState, event, metadata) => {
    setIsOpen(nextOpenState);
  };
  return (
    <>
      <header className="pt-2 pb-2">
        <Container className="p-0">
          {["xl"].map((expand) => (
            <Navbar key={expand} expand={expand}>
              <Container fluid>
                <Navbar.Brand href="#">
                  <Link to="" onClick={redirectTo}>
                    <img
                      src="assets/images/Likebit_Logo_Purple_.png"
                      alt="logo"
                      style={{ width: "283.05px", height: "63.75px" }}
                    />
                  </Link>
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    >
                      <img
                        src="assets/images/Likebit_Logo_Purple_.png"
                        alt=""
                        style={{ width: "145px" }}
                      />
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
              
                    <Nav className="justify-content-end flex-grow-1 pt-2">
                      <Nav.Link href={`${config.baseUrl}`} className={segment == 'likebit' ? "active" : ""} style={{fontSize: '15px'}}>Home</Nav.Link>

                      <Nav.Link
                        href={`${config.baseUrl}about`}
                        className={segment == "about" ? "active" : ""}
                        style={{fontSize: '15px'}}
                      >
                        About Us
                      </Nav.Link>
                      <Nav.Link
                        href={`${config.baseUrl}ourcoin`}
                        className={segment == "ourcoin" ? "active" : ""}
                        style={{fontSize: '15px'}}
                      >
                        Our Coins
                      </Nav.Link>
                      {/* <Nav.Link href={`${config.baseUrl}`}>Roadmap</Nav.Link> */}
                      <Nav.Link
                        href={`${config.baseUrl}tokenomics`}
                        className={segment == "tokenomics" ? "active" : ""}
                        style={{fontSize: '15px'}}
                      >
                        Tokenomics
                      </Nav.Link>
                      <Nav.Link
                        href={`${config.baseUrl}Presale`}
                        className={segment == "Presale" ? "active" : ""}
                        style={{fontSize: '15px'}}
                      >
                        Buy
                      </Nav.Link>

                      <Nav.Link
                            href={`${config.baseUrl}Vesting`}
                            className={segment == "Vesting" ? "active" : ""}
                            style={{fontSize: '15px'}}
                          >
                            Vesting
                          </Nav.Link>
                          
                      {/* <Nav.Link href="assets/images/like_bit_white_paper_Re-designed.pdf" target="_blank">Whitepaper</Nav.Link> */}
                      <Nav onMouseEnter={handleMouseEnter} onMouseLeave={() => setIsOpen(false)}>
                        <Dropdown as={Nav.Item} className="nav-dropdown" show={isOpen} onToggle={handleToggle}>
                          <Dropdown.Toggle as={Nav.Link} id="whitepaper-dropdown" ref={dropdownRef} style={{fontSize: '15px' }}>
                            Whitepaper
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item href="assets/images/WHITEPAPER ENGLISH.pdf" target="_blank">English</Dropdown.Item>
                            <Dropdown.Item href="assets/images/WHITEPAPER GREEK.pdf" target="_blank">Greek</Dropdown.Item>
                            <Dropdown.Item href="assets/images/WHITEPAPER ARABIC.pdf" target="_blank">Arabic</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Nav>
                      {loginData.length == 0 ? (
                        ""
                      ) : (
                        <>
                          {/* <Nav.Link
                            href={`${config.baseUrl}Presale`}
                            className={segment == "Presale" ? "active" : ""}
                          >
                            Buy Now
                          </Nav.Link> */}

                        </>
                      )}
                    </Nav>
                    {loginData.length == 0 ? (
                      <>
                        <Link to={`${config.baseUrl}userlogin`}>
                        <div className="d-flex justify-content-left">
                          <Button variant="custombtn1" className=" px-5 ">
                            Log in
                          </Button>
                          </div>
                        </Link>
                        <Link to={`${config.baseUrl}userregister`}>
                        <div className="d-flex justify-content-left">
                          <Button variant="custombtn2" className="px-5">
                            Sign up
                          </Button>
                          </div>
                        </Link>
                      </>
                    ) : (
                      <>
                        {/* <div className="" style={{ marginTop: "7px" }}>
                          {walletaddres.toString().substring(0, 5) +
                            "..." +
                            walletaddres
                              .toString()
                              .substring(walletaddres.length - 5)}
                        </div> */}
                        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                        {/* <div
                          className=""
                          onClick={logoutAll}
                          style={{ marginTop: "7px", cursor: "pointer" }}
                        >
                          Logout
                        </div> */}
                        <Button
                          variant="custombtn2"
                          className="px-4 mt-lg-0 mt-md-0 mt-4"
                          onClick={logoutAll}
                          style={{ cursor: "pointer" }}
                        >
                          Logout
                        </Button>
                      </>
                    )}
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </Container>
      </header>
    </>
  );
};

export default Header;
