import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  postRequestFormData,
} from "../directives/helper";

export const RegisterAction = (data) => {
  return postRequest("register", data).then((res) => {
    return res.data;
  });
};

export const resetAction = (data) => {
  return postRequest("resetpassword", data).then((res) => {
    return res.data;
  });
};


export const LoginAction = (data) => {
  return postRequest("login", data).then((res) => {
    return res.data;
  });
};

export const forgetAction = (data) => {
  return postRequest("forgetPassword", data).then((res) => {
    return res.data;
  });
};

export const getCountryListAction = () => {
  return postRequest('getCountryList').then(res => { return res.data })
}

export const getStatesByCountryAction = (data) => {
  return postRequest('getStatesByCountry', data).then(res => { return res.data })
}

export const getCitiesByStateAction = (data) => {
  return postRequest('getCitiesByState', data).then(res => { return res.data })
}


export const googleLoginAction = (data) => {
  return postRequest("googleLogin", data).then((res) => {
    return res.data;
  });
};

export const ContactFormAction = (data) => {
  return postRequest("contactus", data).then((res) => {
    return res.data;
  });
};


export const verifyAccountAction = (data) => {
  return postRequest(`verifyAccount/${data}`).then((res) => {
    return res.data;
  });
};

export const ResendEmailAction = (data) => {
  return postRequest("resendemail", data).then((res) => {
    return res.data;
  });
};
