import React, {  useState } from "react";
import "../generalCSS/footer.css";
import { Container, Row, Col, Button, Form, Nav } from 'react-bootstrap';
import { Zoom } from 'react-reveal';
import config from '../config/config';
import {
    ContactFormAction
} from "../Action/user.action";
import toast, { Toaster } from "react-hot-toast";

const Footer = () => {

    const [form, setForm] = useState({ name: '', email: '', message: '' })
    const [validatioError, setvalidatioError] = useState({ nameError: '', emailError: '', messageError: '' });


    const inputHandler = (e) => {
        const { name, value, id } = e.target
        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }

        setForm((old) => {
            return { ...old, [name]: value }
        })
    }

    function validate() {
        let nameError = "";
        let emailError = "";
        let messageError = "";

        if (form.name === '') {
            nameError = "Name is required."
        }
        if (form.email === '') {
            emailError = "Email is required."
        }

        if (form.message === '') {
            messageError = "Message is required."
        }
        if (nameError || emailError || messageError) {
            setvalidatioError({
                nameError, emailError, messageError
            })
            return false
        } else {
            return true
        }
    }

    const SubmitForm = async (e) => {
        e.preventDefault()
        const isValid = validate();
        if (!isValid) {

        }
        else {
            let res = await ContactFormAction(form);
            if (res) {
                toast.success(res.msg);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        }
    }


    return (
        <>

            {/*------------- Footer Start -------------- */}

            <footer className='pb-5'>
                <Toaster />
                <Container>
                    <Row>
                        <Col xl={10} lg={12} md={12} xs={12} className='mx-auto'>
                            <Zoom>
                                <div className='contact_inner'>
                                    <Row>
                                        <Col lg={7} md={6} xs={12} className='p-lg-5 p-md-5 p-0'>
                                            <div className='contactform'>
                                                <div className='headingbox mb-4'>
                                                    <h2>Contact Us</h2>
                                                </div>
                                                <Form >
                                                    <Form.Group className="mb-4">
                                                        {/* <Form.Label>Your Name</Form.Label> */}
                                                        <Form.Control type="text" onChange={inputHandler} name="name" className=' ps-0' placeholder="Your Name" />
                                                        <span className="validationErr" style={{ color: "red" }}>{validatioError.nameError}</span>

                                                    </Form.Group>

                                                    <Form.Group className="mb-4">
                                                        <Form.Control type="email" onChange={inputHandler} name="email" className='ps-0' placeholder="Your Email" />
                                                        <span className="validationErr" style={{ color: "red" }}>{validatioError.emailError}</span>

                                                    </Form.Group>

                                                    <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
                                                        <Form.Control as="textarea" name="message"
                                                            onChange={inputHandler} className='ps-0' placeholder='Your Message' rows={5} />
                                                        <span className="validationErr" style={{ color: "red" }}>{validatioError.messageError}</span>

                                                    </Form.Group>
                                                    <div className='pt-2'>
                                                        <Button variant="custombtn2" type="submit" onClick={SubmitForm} className="px-5">Submit</Button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </Col>
                                        <Col lg={5} md={6} xs={12} className='pe-lg-0 pe-md-0'>
                                            <div className='conatact_details '>
                                                <div className='info_deatils p-lg-4 p-md-4 p-0'>
                                                    <ul>
                                                        <li className=''>
                                                            <div className='d-flex align-items-center'>
                                                                <div>
                                                                    <img src="assets/icons/location.svg" alt="location" className='info_icons me-4' />
                                                                </div>
                                                                <div>
                                                                    <span >VIZVIZI 11, ALEXANDROUPOLI, <br /> GR 681 00</span>
                                                                </div>
                                                            </div>

                                                        </li>
                                                        <li className=''>
                                                            <div className='d-flex align-items-center'>
                                                                <div>
                                                                    <img src="assets/icons/mailicon.svg" alt="mailicon" className='info_icons me-4' />
                                                                </div>
                                                                <div>
                                                                    <span> <a href="" style={{color:'white', textDecoration:"none"}}> info@likebit.io</a></span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        {/* <li className=''>
                                                            <div className='d-flex align-items-center'>
                                                                <div>
                                                                    <img src="assets/icons/phoneicon.svg" alt="phoneicon" className='info_icons me-4' />
                                                                </div>
                                                                <div>
                                                                    <span>+30 6974133084</span>
                                                                </div>
                                                            </div>
                                                        </li> */}

                                                    </ul>
                                                </div>
                                                <div className='social_icons pt-lg-5 pt-md-5 pt-0 container '>
                                                    <ul>
                                                        <li className=''>
                                                            <a href=" https://discord.gg/XnUExy8bF4" target="blank">
                                                                <img src="assets/icons/discord.svg" alt="discord" className='social_icons' />
                                                            </a>
                                                        </li>
                                                        <li className=''>
                                                            <a href="https://gr.pinterest.com/likebitapp/" target="_blank">
                                                                <img src="assets/icons/pinterest.svg" alt="pinterest" className='social_icons' />
                                                            </a>
                                                        </li>
                                                        <li className=''>
                                                            <a href="https://www.linkedin.com/in/likebitapp/" target="_blank">
                                                                <img src="assets/icons/linkedin.svg" alt="linkedin" className='social_icons' />
                                                            </a>
                                                        </li>
                                                        <li className=''>
                                                            <a href="https://www.tiktok.com/@likebitapp" target="blank">
                                                                <img src="assets/icons/tiktok.svg" alt="tiktok" className='social_icons' />
                                                            </a>
                                                        </li>
                                                        <li className=''>
                                                            <a href="https://t.me/likebitapp" target="blank">
                                                                <img src="assets/icons/telegram.svg" alt="telegram" className='social_icons'/>
                                                            </a>
                                                        </li>
                                                        {/* <li className=''>
                                                            <a href="https://twitter.com/" target="blank">
                                                                <img src="assets/icons/twitter.svg" alt="twitter" className='social_icons' />
                                                            </a>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Zoom>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} xs={12} className=''>
                            <div className='pt-5'>
                                <Nav className="f-navigation">
                                    <Nav.Link href={`${config.baseUrl}about`}>about Us</Nav.Link>
                                    {/* <Nav.Link href="#action2">Team</Nav.Link>    */}
                                    <Nav.Link href={`${config.baseUrl}ourcoin`}>Our Coins</Nav.Link>
                                    <Nav.Link href={`${config.baseUrl}tokenomics`}>Tokenomics</Nav.Link>
                                    <Nav.Link href={"assets/images/TermsOfUse.pdf"} target="_blank">Terms of Use</Nav.Link>
                                    <Nav.Link href={"assets/images/PrivacyPolicy.pdf"} target="_blank">Privacy Policy</Nav.Link>
                                    <Nav.Link href={"assets/images/CookiePolicy.pdf"} target="_blank">Cookie Policy</Nav.Link>
                                    {/* <Nav.Link href="#action2">Cookie Policy</Nav.Link> */}
                                </Nav>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12} md={12} xs={12} className='text-center pt-4'>
                            <p className='copyright'>© 2024 LikeBit. All rights reserved.</p>
                            <p className='copyright' > Powered By <a href="https://webinfamous.com/" target="blank" style={{ color: 'white' }}>webinfamous</a></p>
                        </Col>
                    </Row>

                </Container>

                <div className='f-bottmbox'>
                    <Container>
                    </Container>
                </div>
            </footer>


            {/*------------- Footer Exit -------------- */}

        </>
    )
}

export default Footer;
