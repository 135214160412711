import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../config/config";
import "../generalCSS/login.css";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
// import Form from 'react-bootstrap/Form';

import { IoEyeOutline, IoEyeOffSharp } from "react-icons/io5";
import {
  RegisterAction,
  getCountryListAction,
  getStatesByCountryAction,
  getCitiesByStateAction,
} from "../Action/user.action";
import toast, { Toaster } from "react-hot-toast";
import detectEthereumProvider from "@metamask/detect-provider";
import Header from "./header";
import Cookies from "js-cookie";

const Userregister = () => {
  const [validationerror, setvalidationerror] = useState({
    emailerror: "",
    passworderror: "",
  });
  const [showpassword, setshowpassword] = useState(false);
  const [eyeDisplay, setEyedisplay] = useState(true);
  const navigate = useNavigate();
  const [image_file, setimage_file] = useState("");

  const [CountryList, setCountryList] = useState([]);
  const [CountryListMo, setCountryListMo] = useState({});

  const [StateList, setStateList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [checkbox, setcheckbox] = useState(false);
  const [walletaddres, setWalletAddress] = useState(
    !localStorage.getItem("walletaddress")
      ? ""
      : localStorage.getItem("walletaddress")
  );
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [data, setdata] = useState({
    email: "",
    password: "",
    wallet_address: "",
  });
  const connectMetamask = async () => {
    const provider = await detectEthereumProvider({
      mustBeMetaMask: true,
    });

    if (provider) {
      try {
        const accounts = await provider.request({
          method: "eth_requestAccounts",
        });

        // loginWithbnbaddress(accounts[0]);

        localStorage.setItem("walletaddress", accounts[0]);
        setWalletAddress(accounts[0]);
        setIsOpen(false);
        toast.success("Wallet Connected!!");
      } catch (err) {
        if (err.message.toString().split("Insufficient fund")[1]) {
          toast.error("Transaction reverted : " + err.message);
        } else {
          if (err.toString().split("execution reverted:")[1]) {
            toast.error(
              "Transaction reverted : " +
              err
                .toString()
                .split("execution reverted:")[1]
                .toString()
                .split("{")[0]
            );
          } else {
            toast.error(
              err.message ==
                "Already processing eth_requestAccounts. Please wait."
                ? "Please open your MetaMask as the transaction is already in progress."
                : err.message
            );
          }
        }
      }
    } else {
      toast.error("You need to install Metamask Wallet");
      setTimeout(() => {
        window.open("https://metamask.io/download/", "_blank");
      }, 1000);
    }
  };

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = async () => {
    const apiRes = await getCountryListAction();
    if (apiRes.success) {
      setCountryList(apiRes.countryList);
    }
  };

  const getCountryListMo = async (data) => {
    const apiRes = await getCountryListAction();
    if (apiRes.success) {
      setCountryListMo(apiRes.countryList.filter((item) => item.id == data));
    }
  };

  const getStateList = async (country_id) => {
    const apiRes = await getStatesByCountryAction({ id: country_id });
    if (apiRes.success) {
      setStateList(apiRes.statesList);
    }
  };

  const getCitiesList = async (state_id) => {
    const apiRes = await getCitiesByStateAction({ id: state_id });
    if (apiRes.success) {
      setCityList(apiRes.data);
    }
  };

  const logoutMetamask = () => {
    localStorage.removeItem("walletaddress");
    setWalletAddress("");
  };
  const inputhandler = (e) => {
    const { name, value } = e.target;

    setdata({ ...data, [name]: value });
    setvalidationerror({ ...validationerror, [name]: "" });
    console.log("e.target.name", e.target.value);
    if (e.target.name == "country") {
      getCountryListMo(e.target.value);
    }
  };

  const validations = () => {
    let isValid = true;
    const errors = {};
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordFormat =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.*[^\s]).{8,}$/;

    if (!data.email) {
      errors.emailerror = "Enter your email id";
      isValid = false;
    } else if (!emailFormat.test(data.email)) {
      errors.emailerror = "Invalid email format";
      isValid = false;
    }
    if (!data.password) {
      errors.passworderror = "Create you password";
      isValid = false;
    } else if (data.password.length < 8) {
      errors.passworderror = "Password must be at least 8 characters long";
      isValid = false;
    } else if (!passwordFormat.test(data.password)) {
      errors.passworderror =
        "Password must contain at least one capital letter, one small letter, one special character and one numeric";
      isValid = false;
    }

    setvalidationerror(errors);
    return isValid;
  };

  const showpasswordfunc = () => {
    setEyedisplay(true);
    setshowpassword(!showpassword);
  };

  const showpasswordfunc1 = () => {
    setEyedisplay(false);
    setshowpassword(!showpassword);
  };

  const Registeruser = async (e) => {
    e.preventDefault();
    console.log("checkbox", checkbox);
    const isValid = validations();

    if (!isValid || checkbox == false) {
    } else {
      try {
        if (!walletaddres) {
          toast.error(
            "Please connect your wallet for complete registration process"
          );
        } else {
          data.wallet_address = walletaddres;
          console.log(data, "in daata");
          let res = await RegisterAction(data);
          console.log(res, "ressss");
          if (res.success) {
            Cookies.set("emailShow", JSON.stringify(data.email));
            toast.success("The verification email has been successfully sent.");
            setTimeout(() => {
              navigate(`${config.baseUrl}signupsuccess`);
            }, 1000);
          } else {
            toast.error(res.msg);
          }
        }
      } catch (error) {
        toast.error("Something Went Wrong! Please Try Again");
      }

      //
    }
  };
  const handlecheckbox = async () => {
    setcheckbox(true);
  };
  return (
    <>
      {/*----------------------- Login Section Start ----------------- */}

      <Toaster />
      {/* <section className="pt-4">
        <Container>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Link to={`${config.baseUrl}`}>
                <img
                  src="assets/images/Likebit_Logo_Purple_.png"
                  alt="Logo"
                  style={{ width: "145px" }}
                  className="main_logo"
                />
              </Link>
            </Col>
          </Row>
        </Container>
      </section> */}
      <Header />
      <section className="login_sec pt-5">
        <Container>
          <Row className="align-items-center">
            <Col lg={12} md={12} xs={12}>
              <Row>
                <Col lg={6} md={12} xs={12} className="">
                  <div className="getstart_block p-xl-5 p-lg-5 p-md-5 p-3">
                    <div className="headbox">
                      <h1>Get Started</h1>
                      <p className="">
                        Already have an account?{" "}
                        <span>
                          <Link
                            to={`${config.baseUrl}userlogin`}
                            style={{ textDecoration: "underline" }}
                          >
                            Sign In
                          </Link>
                        </span>
                      </p>
                    </div>

                    <div className="google_box" style={{ display: "none" }}>
                      <Row>
                        <Col
                          lg={10}
                          md={12}
                          className="px-xl-5 px-lg-0 px-md-2 my-4 mx-auto"
                        >
                          <Button
                            variant=""
                            type="button"
                            className="googlebtn w-100"
                          >
                            <span>
                              <img
                                src="assets/icons/Google.svg"
                                alt=" Google"
                                className=" Googleicon me-3"
                                style={{ width: "35px", height: "35px" }}
                              />
                            </span>
                            Sign up with Google
                          </Button>
                        </Col>
                      </Row>
                    </div>

                    <div className="ortext my-lg-3 my-md-3 my-2">
                      <span></span>
                      <p>Or</p>
                      <span></span>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      {/* <h4>Connect Wallet</h4> */}
                      {walletaddres ? (
                        <>
                          <div className="copyaddress">
                            <h6 className="my-4">{walletaddres}</h6>
                          </div>
                          <Button
                            onClick={(e) => logoutMetamask()}
                            variant=""
                            type="button"
                            className="py-2 px-4 btn btn-custombtn2"
                          >
                            Disconnect Wallet
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={(e) => connectMetamask()}
                          variant=""
                          type="button"
                          className="py-2 px-4 btn btn-custombtn2"
                        >
                          Connect Wallet
                        </Button>
                      )}
                    </div>
                    <div className="formbox px-lg-4 px-md-0 px-0 mt-lg-5 mt-md-5 mt-4 ">
                      <Form autoComplete="off">
                        {/* <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            name="first_name"
                            onChange={inputhandler}
                            placeholder="Enter First Name"
                            autoComplete="off"
                            required
                          />
                        </Form.Group>
                        <div
                          className="validationerror"
                          style={{ marginTop: "-15px" }}
                        >
                          {validationerror.firstNameerror}
                        </div>
                        <br /> */}
                        {/* <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            name="last_name"
                            onChange={inputhandler}
                            placeholder="Enter Last Name"
                            autoComplete="off"
                            required
                          />
                        </Form.Group>
                        <div
                          className="validationerror"
                          style={{ marginTop: "-15px" }}
                        >
                          {validationerror.lastNameerror}
                        </div>
                        <br /> */}
                        {/* <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Select
                            name="country"
                            style={{ lineHeight: "1.9" }}
                            onChange={(e) => {
                              inputhandler(e);
                              getStateList(e.target.value);
                            }}
                            placeholder="Please enter your country"
                            // value={values.country}
                          >
                            <option value="">Select Country</option>
                            {CountryList.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <div
                          className="validationerror"
                          style={{ marginTop: "-15px" }}
                        >
                          {validationerror.countryerror}
                        </div>
                        <br /> */}
                        {/* 
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Select
                            style={{ lineHeight: "1.9" }}
                            name="state"
                            onChange={(e) => {
                              inputhandler(e);
                              getCitiesList(e.target.value);
                            }}
                            placeholder="Please enter your State"

                            // value={values.state}
                          >
                            <option value="">Select State</option>
                            {StateList.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <div
                          className="validationerror"
                          style={{ marginTop: "-15px" }}
                        >
                          {validationerror.stateerror}
                        </div>
                        <br />

                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Select
                            style={{ lineHeight: "1.9" }}
                            // error={Boolean(errors.city)}
                            // helperText={errors.city}
                            name="town"
                            // onBlur={handleBlur}
                            onChange={(e) => {
                              inputhandler(e);
                            }}
                            // value={values.city}
                          >
                            <option value="">Select City</option>
                            {CityList.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <div
                          className="validationerror"
                          style={{ marginTop: "-15px" }}
                        >
                          {validationerror.townerror}
                        </div>
                        <br />

                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            name="zip_code"
                            onKeyPress={(event) => {
                              if (!/^\d{0,1}$/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={inputhandler}
                            placeholder="Enter Zip Code"
                            autoComplete="off"
                            required
                          />
                        </Form.Group>
                        <div
                          className="validationerror"
                          style={{ marginTop: "-15px" }}
                        >
                          {validationerror.zipCodeerror}
                        </div>
                        <br />

                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            name="postal_address"
                            onChange={inputhandler}
                            placeholder="Enter Postal Address	"
                            autoComplete="off"
                            required
                          />
                        </Form.Group>
                        <div
                          className="validationerror"
                          style={{ marginTop: "-15px" }}
                        >
                          {validationerror.postalAddresserror}
                        </div>
                        <br />

                        {/* {console.log('sss',CountryListMo[0].phonecode)} */}
                        {/* <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Control
                            style={{
                              width: "20%",
                              float: "left",
                              marginRight: "20px",
                            }}
                            type="text"
                            placeholder={
                              CountryListMo[0]?.phonecode
                                ? "+ " + CountryListMo[0]?.phonecode
                                : "Code"
                            }
                            autoComplete="off"
                            disabled
                            required
                          />

                          <Form.Control
                            style={{ width: "75%" }}
                            type="text"
                            onKeyPress={(event) => {
                              if (!/^\d{0,1}$/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            name="mobile_number"
                            onChange={inputhandler}
                            placeholder={"Enter a phone number"}
                            autoComplete="off"
                            required
                          />
                        </Form.Group>
                        <div
                          className="validationerror"
                          style={{ marginTop: "-15px" }}
                        >
                          {validationerror.mobileNumbererror}
                        </div>
                        <br />  */}
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Control
                            type="email"
                            name="email"
                            onChange={inputhandler}
                            placeholder="Enter Email"
                            autoComplete="off"
                            required
                          />
                          <div
                            className="validationerror"
                          >
                            {validationerror.emailerror}
                          </div>
                        </Form.Group>

                        <Form.Group
                          className="position-relative mb-4"
                          controlId="formBasicPassword"
                        >
                          <Form.Control
                            type={`${showpassword ? "name" : "password"}`}
                            name="password"
                            onChange={inputhandler}
                            placeholder="Password"
                            required
                          />
                          <div className="eyeIconBox">
                            {eyeDisplay == false ? (
                              <IoEyeOutline
                                className="eyeicon"
                                style={{ cursor: "pointer" }}
                                onClick={showpasswordfunc}
                              />
                            ) : (
                              <IoEyeOffSharp
                                className="eyeicon"
                                style={{ cursor: "pointer" }}
                                onClick={showpasswordfunc1}
                              />
                            )}
                          </div>
                          <div
                            className="validationerror"
                          >
                            {validationerror.passworderror}
                          </div>
                        </Form.Group>
                        <div className="mb-4">
                          <Form.Group controlId="formBasicCheckbox">
                            <Form.Check
                              type="checkbox"
                              onClick={handlecheckbox}
                              className="fs-6"
                              style={{ color: "black", fontSize: "15px" }}
                              label="I have read terms and conditions and I  agree to the "
                            />
                            <div>
                            <a
                              href="assets/images/TermsOfUse.pdf"
                              className="fs-6"
                              style={{
                                color: "black",
                                fontSize: "15px",
                                marginLeft: "22px",
                              }}
                              target="blank"
                            >
                              Terms Of Use
                            </a>
                            ,&nbsp; 
                            <a
                              href="assets/images/PrivacyPolicy.pdf"
                              className="fs-6"
                              style={{ color: "black", fontSize: "15px" }}
                              target="blank"
                            >
                              Privacy Policy
                            </a>
                            ,&nbsp; 
                            <a
                              href="assets/images/CookiePolicy.pdf"
                              className="fs-6"
                              style={{ color: "black", fontSize: "15px" }}
                              target="blank"
                            >
                              Cookie Policy
                            </a>
                            </div>
                          </Form.Group>
                          
                        </div>
                
                        {/* <h6>Upload document file (Passport/Id Proof):</h6>
                        <Form.Group
                          className=" fieldbox mb-4"
                          controlId="formBasicPassword"
                        >
                          <Form.Control
                            type="file"
                            accept=".png"
                            onChange={profilePicChange}
                            name="image"
                          />
                        </Form.Group>
                        <div
                          className="validationerror"
                          style={{ marginTop: "-15px" }}
                        >
                          {validationerror.imageError}
                        </div>
                        <br /> */}
                        {/* 
                        <Form.Group
                          className="mb-4 checkbox"
                          controlId="formBasicPassword"
                        >
                          <p className="comment-form-cookies-consent">
                            <input
                              id="wp-comment-cookies-consent"
                              name="wp-comment-cookies-consent"
                              type="checkbox"
                              defaultValue="yes"
                            />
                            <label htmlFor="wp-comment-cookies-consent">
                              By signing up, I agree to the{" "}
                              <a href="#">Privacy Policy</a> and{" "}
                              <a href="#">Terms of Service</a>
                            </label>
                          </p>
                        </Form.Group> */}
                        <Row>
                          <Col lg={12} md={12} className=" mx-auto ">
                            {checkbox == false ? (
                              <Button
                                variant="custombtn2"
                                type="submit"
                                disabled
                                className="px-4 py-lg-3 py-md-3 py-2  w-100 text-white"
                                onClick={(e) => Registeruser(e)}
                              >
                                Submit
                              </Button>
                            ) : (
                              <Button
                                variant="custombtn2"
                                type="submit"
                                className="px-4 py-lg-3 py-md-3 py-2  w-100 text-white"
                                onClick={(e) => Registeruser(e)}
                              >
                                Submit
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </Col>

                <Col
                  lg={6}
                  md={12}
                  xs={12}
                  className="d-flex align-items-center"
                >
                  <div className="handcoin_box  py-lg-5 py-md-5 py-3">
                    <img
                      src="assets/images/Handpresale.png"
                      alt="handcoin"
                      className="handcoin"
                    />
                  </div>
                </Col>
              </Row>
              {/* </div> */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Userregister;
