import React, { useEffect, useState } from "react";
import config from "../config/config";
import "../generalCSS/vesting.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Header from "./header";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Table,
} from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import { LuChevronRight } from "react-icons/lu";
import { LuChevronLeft } from "react-icons/lu";
import { useBuyInfo } from "../components/userBuyInfo";
import { useClaimToken } from "../components/claimToken";
import moment from "moment";
import { Dialog, Classes } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import { useAirdropStatus } from "../components/isAirdopActive";
import toast, { Toaster } from "react-hot-toast";

const Vesting = () => {
  const navigate = useNavigate();

  const [walletaddres, setWalletAddress] = useState(
    !localStorage.getItem("walletaddress")
      ? ""
      : localStorage.getItem("walletaddress")
  );
  const { airdropStatus } = useAirdropStatus();

  const [BuyDetails, setBuyDetailss] = useState("");
  const [totalClaimAmount, setTotalClaimAmount] = useState("");

  const { buyInfo } = useBuyInfo();
  const { claimTokens, isDialogOpen } = useClaimToken();

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const info = await buyInfo(walletaddres);
      let _TotalClaimAmount = 0;
      info?.claimed.filter((item, index) => {
        if (item) {
          _TotalClaimAmount += parseInt(info.tokensBought[index]) / 10 ** 18;
        }
      });
      setTotalClaimAmount(_TotalClaimAmount);
      setBuyDetailss(info);
      // ...
    }
    fetchData();
    
  }, [walletaddres]);

  const claimAction = async (id, index) => {
    const info = await airdropStatus(id);

    if (info == true) {
      await claimTokens(id, index);
    } else {
      toast.error("AirDrop Not started for this Phase");
    }
  };

  const logoutAll = () => {
    Cookies.remove("likeBitUser");
    localStorage.removeItem("walletaddress");
    setTimeout(() => {
      window.location.href = `${config.baseUrl}`;
    }, 1000);
  };

  const convertTimestampToDate = (timestamp) => {
      const date = new Date(timestamp * 1000);
    const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if needed
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (zero-based) and pad with leading zero if needed
    const year = date.getFullYear(); // Get full year
    return `${day}/${month}/${year}`; // Format as dd/mm/yyyy
    
};


  return (
    <>
      <Toaster />

      <Dialog
        title="Please Wait..."
        // icon="warning-sign"
        style={{
          color: "#3fa1f3",
          textAlign: "center",
        }}
        isOpen={isDialogOpen}
        isCloseButtonShown={false}
      >
        <div className="text-center">
          {/* <BarLoader color="#e84747" height="2" /> */}
          <br />
          <h5 style={{ color: "#3fa1f3" }}>
          The claiming process is in progress. Once it's completed, the data will be displayed on the vesting page.
          </h5>
          <p style={{ color: "#091f3f" }}>
            Please do not refresh page or close tab.
          </p>
          <div>
            <div className="spinner-border"></div>
          </div>
        </div>
      </Dialog>

      <Header />

      <section className="vesting_sec pt-5">
        <Container>
          <Row>
            <Col lg={12} md={12} xs={12} className="py-5">
              <div className="your_balance mb-4">
                <h2>Your Vesting Balance</h2>
                <h4>
                  {totalClaimAmount
                    ? parseFloat(totalClaimAmount).toFixed(2)
                    : "0.00"}{" "}
                  LBT
                </h4>
              </div>
              <div className="token_history pt-5">
                <h4>Vesting Wallet (Token Purchase History)</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <div className="history_table">
                <div className="table-responsive">
                  <Table striped bordered hover>
                    <thead>
                      <tr className="">
                        <th>#</th>
                        <th>Amount (LBT)</th>
                        <th>Phase</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {BuyDetails?.phaseId?.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{BuyDetails?.tokensBought[index] / 10 ** 18}</td>
                          <td>{item}</td>

                          <td>
                            {convertTimestampToDate(BuyDetails?.date[index])}
                          </td>
                          <td>
                            {BuyDetails?.claimed[index] == false ? (
                              <button
                                className="btn btn-primary"
                                onClick={() => claimAction(item, index)}
                              >
                                Claim
                              </button>
                            ) : (
                              <button className="btn btn-small" disabled>
                                Claimed
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <div className="shoving_data" style={{ display: "none" }}>
                    <Row>
                      <Col lg={6} md={6} sm={6} xs={12}>
                        <p className="showtext mb-0">
                          Showing 1 to 0 of 0 entries
                        </p>
                      </Col>
                      <Col lg={6} md={6} sm={6} xs={12}>
                        <div className="chevronicons">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination">
                              <li className="page-item">
                                <a className="page-link " href="#">
                                  <LuChevronLeft className="chevicon me-2" />
                                  Previous
                                </a>
                              </li>
                              {/* <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                Previous
                                                            </a>
                                                        </li> */}
                              {/* <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                1
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                2
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                3
                                                            </a>
                                                        </li> */}
                              {/* <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                Next
                                                            </a>
                                                        </li> */}
                              <li className="page-item">
                                <a className="page-link" href="#">
                                  Next
                                  <LuChevronRight className="chevicon ms-2" />
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Vesting;
